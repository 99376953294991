import React, { useState, useEffect } from 'react';
import Slider from "../../components/Common/Slider"
import Container from '@mui/material/Container';
import BedMetrix from '../../components/Property/bedMatrix'
import Button from '@mui/material/Button';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import PropertyDescription from '../../components/Property/propertyDescription';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchProperty } from '../../api/property';
import { CircularProgress } from '@mui/material';
import { HouseRuleModal } from '../../components/Property/houseRuleModal';
import PropertyReviews from '../../components/Property/PropertyReviews';
import { socket } from '../../utils/socket';
import { PROPERTY_STATUS, SOCKET_EVENT } from '../../common/constant';
import { getS3BaseUrl } from '../../helpers/string_helper';

export const PropertyDetail = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [isLoading, setIsLoading] = useState(false)
    const [propertyDetails, setPropertyDetails] = useState();
    const [basicPropertyData, setBasicPropertyData] = useState();
    const location = useLocation();
    const navigate = useNavigate();
    const [socketData, setSocketData] = useState();
    const [foodDocument, setFoodDocument] = useState();
    const s3BaseUrl = getS3BaseUrl()

    useEffect(() => {
        if (location?.state?.propertyId) {
            const property = JSON.parse(location?.state?.propertyId)
            fetchPropertyDetails(property?.id)
            setBasicPropertyData(property);
        } else {
            navigate("/")
        }
    }, [location?.state?.propertyId])

    const fetchPropertyDetails = async (id) => {
        try {
            setIsLoading(true)
            const response = (await fetchProperty(id, { type: 'website' })).data.data;
            setPropertyDetails(response);
        } catch (err) {
            console.log(err)
        } finally {
            setIsLoading(false)
        }
    }

    const proceedToSchedule = (property) => {
        return navigate("/schedule-form", {
            state: { property, }
        })
    }

    useEffect(() => {
        if (socket) {
            socket.on(SOCKET_EVENT.PROPERTY_STATUS_EMIT, (data) => {
                if (!socketData || (socketData?.status !== data?.data?.status?.label && socketData?.id !== data?.data?.property_id)) {
                    setSocketData({
                        status: data?.data?.status?.label,
                        id: data?.data?.property_id
                    })
                }
            });
            socket.on(SOCKET_EVENT.FOOD_MENU_EMIT, (data) => {
                const property = JSON.parse(location?.state?.propertyId)
                if ((!foodDocument || foodDocument?.property_id !== data?.data?.property_id) && data?.data?.status === PROPERTY_STATUS.LIVE && property?.id === data?.data?.property_id) {
                    setFoodDocument({
                        documents: data?.data?.documents,
                        property_id: data?.data?.property_id,
                        status: data?.data?.status
                    })
                }
            });
        }
    }, [socket]);

    useEffect(() => {
        if (socketData?.id === propertyDetails?.id && socketData?.status === PROPERTY_STATUS.ACTIVE) {
            navigate('/')
        }
    }, [socketData])

    useEffect(() => {
        if (basicPropertyData?.id === foodDocument?.property_id && foodDocument?.documents) {
             const food_menu_document = []
                    let isNewFoodMenu  = false
                    if (foodDocument?.documents?.length > 0) {
                       foodDocument?.documents?.forEach((menu) => {
                            if (menu?.size) {
                                isNewFoodMenu = true
                                const filterDocument = basicPropertyData?.food_menu_documents?.find((item) => item?.id === menu?.id)
                                if (!filterDocument) {
                                    food_menu_document.push({ ...menu, location: `${s3BaseUrl}${menu?.location}` })
                                }
                            }
                        })
                    }

                    const documents = isNewFoodMenu && basicPropertyData?.food_menu_documents?.length > 0 ? [...basicPropertyData.food_menu_documents, ...food_menu_document] : (isNewFoodMenu ? food_menu_document : foodDocument?.documents)
                    setBasicPropertyData({
                        ...basicPropertyData,
                        food_menu_documents: documents
                    })
        }

    }, [foodDocument])

    return (
        <div className='detail_pg'>
            {isLoading && !propertyDetails && (
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                </div>
            )}
            {propertyDetails && (
                <section>
                    <Container>
                        <section className='available_room_detail'>
                            <div className='property_detail_block d-grid'>
                                <PropertyDescription propertyDetails={basicPropertyData} />
                                <Slider images={basicPropertyData?.pictures} />
                            </div>
                            <div className='bed_matrix_block'>
                                <div className='bed_matrix_header d-flex align-items-center justify-content-between mb-2'>
                                    <div className='page_header'>
                                        <h2>Bed Arrangements</h2>
                                    </div>
                                    <div className="schedule_btns">
                                        {propertyDetails?.rules?.length > 0 && (
                                            <Button className='theme_button sign_in_button me-2' style={{ zIndex: 0 }} onClick={handleOpen}><RemoveRedEyeOutlinedIcon className='me-2' />House Rule</Button>
                                        )}
                                        <Button className="property_common_btn red_bordered_btn" onClick={() => proceedToSchedule(basicPropertyData)}><CalendarMonthOutlinedIcon /> <span>Schedule Visit</span></Button>
                                    </div>
                                </div>
                                <BedMetrix propertyDetails={propertyDetails} basicDetails={{ ...basicPropertyData, caution_money_type: propertyDetails?.caution_money_type, fixed_rate: propertyDetails?.fixed_rate, user_id: propertyDetails.user_id, rules: propertyDetails.rules, global_data: propertyDetails?.global_data }} />

                                {propertyDetails?.id
                                    &&
                                    (<PropertyReviews
                                        scrollToReview={location?.state?.scrollToReview}
                                        propertyId={propertyDetails?.id}
                                    />)}
                            </div>
                        </section>
                    </Container>
                </section>
            )}

            {open && (
                <HouseRuleModal open={open} handleClose={handleClose} rules={propertyDetails?.rules} />
            )}
        </div>
    )
}