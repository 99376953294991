import React, { useState } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
// import phonepeImg from '../assets/images/Phonepay 1.svg';
// import paymentCashfreeImg from '../assets/images/Cashfree payment 1.svg';
// import visaImg from '../assets/images/visa 1.svg';
import { FormControl, FormLabel } from '@mui/material';

function PaymentModeSelector({ modal, toggle, isLoading, phonePeHandler, cashfreeHandler, totalAmount }) {
  const paymentOptions = {
    PHONEPE: 'PhonePe',
    CASHFREE: 'Cashfree'
  }
  const [paymentMode, setPaymentMode] = useState(paymentOptions.CASHFREE)
  const handlePay = () => {
    switch (paymentMode) {
      case paymentOptions.CASHFREE:
        cashfreeHandler();
        toggle();
        break;
      case paymentOptions.PHONEPE:
        phonePeHandler();
        toggle();
        break;
      default:
        break;
    }
  }
  return (
    <Modal isOpen={modal} toggle={toggle} className='payment_modal'>
      <ModalHeader toggle={toggle}>Payments</ModalHeader>
      <ModalBody>
        <div className='d-flex justify-content-between align-items-center total_amount_wrap p-3 mb-3'>
          <div>
            <p className='mb-0'>Total Amount</p>
          </div>
          <div><p className='mb-0'>₹{totalAmount}</p></div>
        </div>
        <div className='dashboard_accordion_holder'>
          {/* <Accordion className='mb-2'
            sx={{
              borderRadius: '6px',
              boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.10)',
              border: '1px solid #c0c7d5',
            }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: '500',
                }} component="span"><img alt='payment with phonepe' src={phonepeImg} /> Payment with PhonePe</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Accordion className='cashfree_accordion'>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography component="span"
                    sx={{
                      fontSize: '16px',
                      fontWeight: '500',
                    }}><img alt='visa' src={visaImg} /> Option to Select UPI</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ borderBottom: '1px solid #d0d4d8', borderTop: "1px solid #d0d4d8" }}>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="googlepay"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel value="googlepay" control={<Radio />} label={
                            <Typography sx={{ color: '#737373' }}>
                              Google Pay
                            </Typography>
                          } />
                        </RadioGroup>
                      </div>
                      <div><p>0.9%</p></div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="payTm"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel value="payTm" control={<Radio />} label={
                            <Typography sx={{ color: '#737373' }}>
                              PayTm
                            </Typography>
                          } />
                        </RadioGroup>
                      </div>
                      <div><p>0.6%</p></div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="payPal"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel value="payPal" control={<Radio />} label={
                            <Typography sx={{ color: '#737373' }}>
                              PayPal
                            </Typography>
                          } />
                        </RadioGroup>
                      </div>
                      <div><p>0.3%</p></div>
                    </div>
                  </div>
                  <div style={{ borderBottom: '1px solid #d0d4d8' }}>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="credit/debit card"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel value="credit/debit card" control={<Radio />} label={
                            <Typography sx={{ color: '#737373' }}>
                              Credit / Debit Card
                            </Typography>
                          } />
                        </RadioGroup>
                      </div>
                      <div><p>0.9%</p></div>
                    </div>
                  </div>
                  <div>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="net banking"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel value="net banking" label={
                            <Typography sx={{ color: '#737373' }}>
                              Net Banking
                            </Typography>
                          } control={<Radio />} />
                        </RadioGroup>
                      </div>
                      <div><p>0.9%</p></div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{
              borderRadius: '6px',
              boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.10)',
              border: '1px solid #c0c7d5',
            }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography component="span"
                sx={{
                  fontSize: '18px',
                  fontWeight: '500',
                }}><img alt='payment with cashfree' src={paymentCashfreeImg} /> Payment with Cashfree</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Accordion className='cashfree_accordion'>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography component="span"
                    sx={{
                      fontSize: '16px',
                      fontWeight: '500',
                    }}><img alt='visa' src={visaImg} /> Option to Select UPI</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ borderBottom: '1px solid #d0d4d8', borderTop: '1px solid #d0d4d8' }}>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="googlepay"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel value="googlepay" control={<Radio />} label={
                            <Typography sx={{ color: '#737373' }}>
                              Google Pay
                            </Typography>
                          } />
                        </RadioGroup>
                      </div>
                      <div><p>0.9%</p></div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="payTm"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel value="payTm" control={<Radio />} label={
                            <Typography sx={{ color: '#737373' }}>
                              PayTm
                            </Typography>
                          } />
                        </RadioGroup>
                      </div>
                      <div><p>0.6%</p></div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="payPal"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel value="payPal" control={<Radio />} label={
                            <Typography sx={{ color: '#737373' }}>
                              PayPal
                            </Typography>
                          } />
                        </RadioGroup>
                      </div>
                      <div><p>0.3%</p></div>
                    </div>
                  </div>
                  <div style={{ borderBottom: '1px solid #d0d4d8' }}>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="credit/debit card"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel value="credit/debit card" control={<Radio />} label={
                            <Typography sx={{ color: '#737373' }}>
                              Credit / Debit Card
                            </Typography>
                          } />
                        </RadioGroup>
                      </div>
                      <div><p>0.9%</p></div>
                    </div>
                  </div>
                  <div>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="net banking"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel value="net banking" label={
                            <Typography sx={{ color: '#737373' }}>
                              Net Banking
                            </Typography>
                          } control={<Radio />} />
                        </RadioGroup>
                      </div>
                      <div><p>0.9%</p></div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </AccordionDetails>
          </Accordion> */}
          <FormControl className='m-auto w-fit' style={{ margin: 'auto', width: 'fit-content' }}>
            <FormLabel id="demo-radio-buttons-group-label">Select payment option</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={paymentOptions.CASHFREE}
              name="radio-buttons-group"
              onChange={(e) => setPaymentMode(e.target.value)}
            >
              <FormControlLabel value={paymentOptions.CASHFREE} control={<Radio />} label="Cashfree" />
              <FormControlLabel value={paymentOptions.PHONEPE} control={<Radio />} label="PhonePe" />
            </RadioGroup>
          </FormControl>
        </div>
        <div className='d-flex justify-content-center align-items-center mt-3'>
          <Button
            style={{ width: '238px' }}
            className='theme_button sign_in_button p-2'
            onClick={handlePay}>Pay ₹{totalAmount}
          </Button>
        </div>
      </ModalBody>
    </Modal >)
}

export default PaymentModeSelector