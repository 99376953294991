import React, { useState, useEffect, useRef } from 'react';
import Container from '@mui/material/Container';
import Testimonial from '../../components/Common/testimonial';
import DashboardInfoCards from './dashboardInfo';
import { TypeAnimation } from 'react-type-animation';
import LocationSearchBar from '../../components/Common/locationSearchbar';
import { Card, Stack, Button } from '@mui/material';
import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { MenuButton as BaseMenuButton } from '@mui/base/MenuButton';
import { MenuItem as BaseMenuItem, menuItemClasses } from '@mui/base/MenuItem';
import { styled } from '@mui/system';
import FormatLineSpacingOutlinedIcon from '@mui/icons-material/FormatLineSpacingOutlined';
import { PropertySlider } from "../../components/Property/PropertyPictureSlider";
import Filter from '../../components/Common/Filter';
import { getAllProperties } from '../../api/property';
import { socket } from '../../utils/socket';
import { getCMSDetails } from '../../api/global-settings';
import { CMS_PAGES } from '../../constants/variables';
import { DEFAULT_PER_PAGE_VALUE, PROPERTY_STATUS, ROOM_TYPES, SOCKET_EVENT } from '../../common/constant';
import { CalendarMonthOutlined, HotelOutlined, LocalHotelOutlined, RemoveRedEyeOutlined, RestaurantOutlined, ShoppingBasketOutlined, Star } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { getS3BaseUrl } from '../../helpers/string_helper';
import { amber } from '@mui/material/colors';
import AddressComponent from '../../components/Common/Address';
import SuitableTagsDesc from '../../components/Common/suitableTagsDescription';
import Amenities from '../../components/Common/amenitiesLanding';
import { selectAmenitiesIcon, selectGenderPreferenceIcon } from '../../components/Common/amenities_icon';
import { FoodMenuDocumentSlider } from '../../components/Property/foodMenuSlider';
import { toast } from 'react-toastify';

export const Dashboard = () => {
  const [data, setData] = useState({})
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#333333',
  };
  const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#99CCF3',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E6',
    700: '#0059B3',
    800: '#004C99',
    900: '#003A75',
  };
  const Listbox = styled('ul')(
    ({ theme }) => `
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 6px;
    margin: 12px 0;
    min-width: 200px;
    overflow: auto;
    outline: 0px;
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    z-index: 1;
    border: 1px solid #737373;
    border-radius: 10px;
    box-shadow: 0 114px 32px 0 rgba(0, 0, 0, 0), 0 73px 29px 0 rgba(0, 0, 0, 0.01), 0 41px 25px 0 rgba(0, 0, 0, 0.05), 0 18px 18px 0 rgba(0, 0, 0, 0.09), 0 5px 10px 0 rgba(0, 0, 0, 0.10);
    `,
  );
  const MenuItem = styled(BaseMenuItem)(
    ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;
  user-select: none;

  &:last-of-type {
    border-bottom: none;
  }

  &:focus {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.${menuItemClasses.disabled} {
    color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }
  `,
  );

  const MenuButton = styled(BaseMenuButton)(
    ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 16px;
  border-radius: 8px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

  &:hover {
    background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
  }

  &:active {
    background: ${theme.palette.mode === 'dark' ? grey[700] : grey[100]};
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
    outline: none;
  }
  `,
  );
  const [propertyList, setPropertyList] = useState([]);
  const filterData = useRef();
  const [socketData, setSocketData] = useState();
  const orderBy = useRef(null);
  const position = useRef(null);
  const hasMore = useRef(false);
  const page = useRef(1);
  const navigate = useNavigate();
  const [isFoodMenu, setIsFoodMenu] = useState(false);
  const [foodMenuFiles, setFoodMenuFiles] = useState();
  const [isLoading, setIsLoading] = useState(false);
  // const [page, setPage] = useState(1);
  const s3BaseUrl = getS3BaseUrl();
  const [foodDocument, setFoodDocument] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [onMounted, setOnMounted] = useState(false);
  const [count, setCount] = useState(0)

  useEffect(() => {
    if (!onMounted) {
      fetchAllProperty({ page: 1 })
      fetchPageDetails()
    }
  }, [])

  useEffect(() => {
    if (socket) {
      socket.on(SOCKET_EVENT.PROPERTY_STATUS_EMIT, (data) => {
        if (!socketData || (socketData?.status !== data?.data?.status?.label && socketData?.id !== data?.data?.property_id)) {
          setSocketData({
            status: data?.data?.status?.label,
            id: data?.data?.property_id
          })
        }
      });
      socket.on(SOCKET_EVENT.FOOD_MENU_EMIT, (data) => {
        if ((!foodDocument || foodDocument?.property_id !== data?.data?.property_id) && data?.data?.status === PROPERTY_STATUS.LIVE) {
          setFoodDocument({
            documents: data?.data?.documents,
            property_id: data?.data?.property_id,
            status: data?.data?.status
          })
        }
      });
    }
  }, [socket]);

  useEffect(() => {
    if (socketData?.status === PROPERTY_STATUS.ACTIVE) {
      const result = propertyList?.filter((item) => item?.id !== socketData?.id)
      setPropertyList(result)
    } else if (propertyList?.length >= 0 && onMounted && socketData?.status === PROPERTY_STATUS.LIVE) {
      const query = {
        ...(position?.latitude && {
          ...position.current
        }),
        ...(filterData?.current && {
          ...filterData?.current
        })
      }
      fetchAllProperty(query)
    }
  }, [socketData])

  useEffect(() => {
    if (foodDocument?.status === PROPERTY_STATUS.LIVE && foodDocument?.property_id && propertyList?.length > 0) {
      const filterData = propertyList?.map((item) => {
        if (item?.id === foodDocument?.property_id) {
          const food_menu_document = []
          if (foodDocument?.documents[0]?.extension) {
            foodDocument?.documents?.forEach((menu) => {
              food_menu_document.push({ ...menu, location: `${s3BaseUrl}${menu?.location}` })
            })
          }

          const documents = foodDocument?.documents[0]?.extension && item?.food_menu_documents?.length > 0 ? [...item?.food_menu_documents, ...food_menu_document] : (foodDocument?.documents[0]?.extension ? food_menu_document : foodDocument?.documents)
          if (item?.id === foodMenuFiles?.id && isFoodMenu) {
            setFoodMenuFiles({
              ...item,
              food_menu_documents: documents
            })
            documents?.length === 0 && setIsFoodMenu(false)
          }

          return {
            ...item,
            food_menu_documents: documents
          }
        } else {
          return item
        }
      })
      setPropertyList(filterData)
    }
  }, [foodDocument])

  const fetchAllProperty = async (query = '', key = '') => {
    try {
      const payload = {
        ...query,
        ...(position?.current && key !== 'location' && {
          ...position.current,
        }),
        ...(filterData?.current && key !== 'filter' && {
          ...filterData.current,
        }),
        ...(orderBy.current && key !== 'order' && {
          order: 'min_bed_price',
          direction: orderBy.current,
        }),
        ...(key !== 'loading' && {
          page: 1
        })
      }

      if (key === 'location') {
        position.current = query ? query : ''
      }
      if (key === 'filter') {
        filterData.current = query
      }
      if (key === 'order') {
        orderBy.current = query.direction
      }
      if (key === 'loading') {
        page.current = query.page
      }

      const response = (await getAllProperties(payload)).data.data;
      setOnMounted(true)
      if (response?.meta?.total === 0 && key !== 'loading') {
        setPropertyList([])
        return toast.error('No match data found')
      }
      hasMore.current = response.meta.next !== null
      const result = response?.data?.map((item) => {
        return {
          ...item,
          bedType: item?.bed_type ? item?.bed_type?.split(',') : [],
          pictures: item?.pictures?.length ? item.pictures?.map((pic) => {
            return { ...pic, location: `${s3BaseUrl}${pic?.location}` }
          }) : [],
          food_menu_documents: item?.food_menu_documents?.length ? item.food_menu_documents?.map((menu) => {
            return { ...menu, location: `${s3BaseUrl}${menu?.location}` }
          }) : [],
          amenities: Array.isArray(item?.amenities) ? item.amenities : [],
        }
      })
      setPropertyList((prev) => key === 'loading' ? [...prev, ...result] : result);
      if (key !== 'loading' && page !== 1) {
        page.current = 1
        setCount(!count)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const fetchPageDetails = async () => {
    try {
      const resp = await getCMSDetails(CMS_PAGES.HOME);
      setData(resp.data.data)
    } catch (e) {
      console.log(e);
    }
  }

  const handleSorting = async (orderBy) => {
    await fetchAllProperty({
      order: 'min_bed_price',
      direction: orderBy,
    }, 'order')
  }

  const fetchAllPropertyList = async (queryParams) => {
    try {
      setIsLoading(true);
      setTimeout(async () => {
        let params = {
          page: page.current + 1,
        };
        hasMore.current = false
        await fetchAllProperty(params, 'loading')
        setIsLoading(false);
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  }

  // Check if the user is near the bottom of the page
  const handleScroll = () => {
    const element = document?.getElementById('footer_id')?.clientHeight
    const cardHeight = document?.getElementById('card_property')?.clientHeight
    if (
      window.innerHeight + window.scrollY >= (document.body.offsetHeight - element - cardHeight) &&
      !isLoading && hasMore.current
    ) {
      fetchAllPropertyList();
    }
  };

  const fetchRoomType = (data) => {
    let bedType = data.split(' ')[0] === ROOM_TYPES.NON_AC ? 'Non AC' : 'AC'
    data.split(' ')?.splice(1)?.forEach((item) => {
      bedType = `${bedType} ${item}`
    })
    return bedType
  }

  const avarageRating = (ratings) => {
    return ratings.reduce((acc, curr) => acc + curr.rating, 0) / ratings.length;
  }

  const handlePropertyClick = (property, scrollToReview = false) => {
    navigate('/property-detail', {
      state: {
        propertyId: JSON.stringify(property),
        scrollToReview
      }
    });
  };

  const handleHiddenList = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const toggleFoodMenu = () => {
    setIsFoodMenu(!isFoodMenu)
  }

  const proceedToSchedule = (property) => {
    return navigate("/schedule-form", {
      state: { property, }
    })
  }

  // Attach scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading, count]);

  return (
    <div className='landing_pg'>
      {/* Banner section starts here */}
      <section className='home_search_banner'>
        <Container>
          <div className='main_banner_content'>
            <div className='page_sub_heading'>
              <h2>
                {/* Near: AMITY . TCS . Biswa Bangla Gate . HIDCO etc */}
                {data?.main_banner_texts?.length &&
                  (<TypeAnimation
                    sequence={[...data?.main_banner_texts].flatMap((text) => [text, 1000])}
                    style={{ fontSize: '2em' }}
                    repeat={Infinity}
                    speed={{ type: "keyStrokeDelayInMs", value: 150 }}
                  />)}
              </h2>
            </div>
            <p className='inner_header'
              dangerouslySetInnerHTML={{ __html: data?.sub_banner }}
            >
              {/* Stress free rent searching */}
            </p>
            <div className='main_banner_search'>
              <LocationSearchBar fetchAllProperty={fetchAllProperty} />
            </div>
          </div>
        </Container>
      </section>
      {/* Dashboard Information cards starts here */}
      <section>
        <Container>
          {
            data?.usp_cards?.length &&
            (<DashboardInfoCards data={data?.usp_cards} />)}
        </Container>
      </section>
      {/* Available rooms listing starts here */}
      <section className='available_rooms_listing'>
        <Container>
          <div className='page_sub_heading d-flex justify-content-between align-items-center'>
            <div><h2 className='mb-0 available_sub_heading'>Our Exclusive Properties</h2></div>
            <div className='filters d-flex'>
              <div className='filterBy me-2 mb-3'>
                <Filter emitFilterData={fetchAllProperty} />
              </div>
              <div className='filterBy me-2'>
                <Dropdown>
                  <MenuButton><FormatLineSpacingOutlinedIcon /><span className='text'>Sort by</span></MenuButton>
                  <Menu slots={{ listbox: Listbox }}>
                    <MenuItem onClick={() => {
                      handleSorting('asc')
                    }
                    }>Price - Low to High</MenuItem>
                    <MenuItem onClick={() => {
                      handleSorting('desc')
                    }}>Price - High to Low</MenuItem>
                  </Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          {propertyList?.length > 0 && (
            <div className="property_listing">
              {propertyList?.length > 0 && propertyList.map((item, index) => (
                <Card className="property_card" id="card_property" key={index}>
                  <div className="single_property_card">
                    <div className="slider-container property_img">
                      <PropertySlider pictures={item?.pictures} index={index} />
                    </div>
                    <div className="content-container">
                      <div className="property_header d-flex justify-content-between align-items-start">
                        <div>
                          <h2 onClick={() => handlePropertyClick(item)} style={{ cursor: 'pointer' }}>{item?.name}</h2>

                          {item?.property_rating?.length > 0 ? (
                            <Stack direction='row' alignItems="center" spacing={0.5} className='d-flex'>
                              <div> {avarageRating(item.property_rating)} </div>
                              <Star sx={{ color: amber[500] }} />
                              <div>
                                {`Based on ${item.property_rating?.length} ratings `}
                                <span
                                  style={{ cursor: 'pointer', color: '#0752C3', 'text-decoration': 'underline' }}
                                  onClick={() => handlePropertyClick(item, true)}
                                >
                                  {!!item?._count.comments ? `(${item?._count.comments} reviews)` : ''}
                                </span>
                              </div>
                            </Stack>
                          )
                            : (<Stack direction='row' alignItems="center" spacing={0.5} className='d-flex my-2' style={{ fontSize: '14px' }}>
                              {/* No rating found */}
                            </Stack>)}

                        </div>

                        <div className="header_btns d-flex justify-content-between">
                          {item?.gender_preference && (
                            <Button className='theme_button sign_in_button' style={{ zIndex: 2, cursor: 'none' }} >{selectGenderPreferenceIcon(item?.gender_preference)}</Button>
                          )}
                          <div className="ribbons-wrapper">
                            <span className="ribbon"> <LocalHotelOutlined />&nbsp; <span>{(item?.vacant_bed_count === 0 && item?._count?.vacant_requests > 0) ? 'Pre Booking Available' : `${item?.vacant_bed_count} Beds Available`}</span></span>
                          </div>
                        </div>
                      </div>
                      <div className="property_content">
                        <div className="main_location d-flex mb-2">
                          {/* <RoomOutlinedIcon className="main_icon" /> */}
                          <p className="body_font_sm mb-0"><AddressComponent address={item.address} /> <a href={`https://www.google.com/maps/dir/?api=1&destination=${item?.latitude},${item?.longitude}&dir_action=navigate`} target="_blank" rel="noreferrer">Google Map View</a></p>
                        </div>
                        <div className="tags_block align-items-center mb-2" style={{
                          display: item?.near_to ? 'flex' : 'none'
                        }}>
                          <div className="tag_heading">
                            {/* <MyLocationOutlinedIcon className="main_icon theme_color" /> */}
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_4268_10371)">
                                <path d="M8.66699 0.666626L8.66766 2.70797C11.0798 3.00906 12.9916 4.92105 13.2924 7.33329H15.3337V8.66663L13.2923 8.66729C12.9913 11.0792 11.0796 12.9909 8.66766 13.292L8.66699 15.3333H7.33366V13.292C4.92141 12.9912 3.00943 11.0794 2.70833 8.66729L0.666992 8.66663V7.33329H2.70825C3.00907 4.92082 4.92119 3.00871 7.33366 2.70789V0.666626H8.66699ZM8.00033 3.99996C5.79119 3.99996 4.00033 5.79082 4.00033 7.99996C4.00033 10.2091 5.79119 12 8.00033 12C10.2095 12 12.0003 10.2091 12.0003 7.99996C12.0003 5.79082 10.2095 3.99996 8.00033 3.99996ZM8.00033 6.66663C8.73673 6.66663 9.33366 7.26356 9.33366 7.99996C9.33366 8.73636 8.73673 9.33329 8.00033 9.33329C7.26393 9.33329 6.66699 8.73636 6.66699 7.99996C6.66699 7.26356 7.26393 6.66663 8.00033 6.66663Z" fill="#ED3542" />
                              </g>
                              <defs>
                                <clipPath id="clip0_4268_10371">
                                  <rect width="16" height="16" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="font_bold font_black mx-2" style={{ fontSize: '14px' }}>Suitable For</span></div>
                          <div>
                            <SuitableTagsDesc tags={item?.near_to?.split(',')} onClick={handleHiddenList} />
                          </div>
                        </div>
                        <div className="amenities" style={{ display: item?.amenities?.length ? 'flex' : 'none' }}>
                          <Amenities tags={item?.amenities?.map(am => ({ amenity: am, icon: selectAmenitiesIcon(am?.toLowerCase()) }))} onClick={handleHiddenList} />
                        </div>
                        <div className="bed_type d-flex align-items-start">
                          <div className="bed_type_header">
                            <HotelOutlined className="theme_color me-0" /> <span style={{ fontSize: '14px' }} className="font_bold font_black mx-2">Bed Type:</span>
                          </div>
                          <ul className="list-inline d-flex mb-0">
                            {item.bedType?.length > 0 && item.bedType.map((room, roomIndex) => (
                              <li key={roomIndex} className={room?.split(' ')?.includes('NC') ? 'tag_block default_block' : "tag_block primary_block"}>
                                {fetchRoomType(room)}
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="amount_block d-flex justify-content-between align-items-start">
                          <div className="d-flex flex-column">
                            <div className="amt d-flex align-items-center">
                              <ShoppingBasketOutlined className="main_icon theme_color" />
                              <span style={{ fontSize: '14px' }} className="body_font_sm font_bold font_black">Starts from</span>&nbsp;<span className="main_rate">₹{item.min_bed_price}</span>
                            </div>
                          </div>
                          <div className="schedule_btns">
                            {item?.food_menu_documents?.length > 0 && (
                              <Button className='property_common_btn red_bordered_btn' onClick={() => {
                                setFoodMenuFiles(item)
                                toggleFoodMenu()
                              }} ><RestaurantOutlined /> <span>Food Menu </span></Button>
                            )}
                            <Button className="property_common_btn red_bordered_btn" style={{ marginLeft: '10px' }} onClick={() => proceedToSchedule(item)}><CalendarMonthOutlined /> <span>Schedule Visit</span></Button>
                            <Button className="property_common_btn yellow_btn" onClick={() => handlePropertyClick(item)}><RemoveRedEyeOutlined /><span>View Details</span></Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              ))}

              {isFoodMenu && <FoodMenuDocumentSlider isOpen={isFoodMenu} handleClose={toggleFoodMenu} files={foodMenuFiles?.food_menu_documents} />}
              {isLoading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <button
                    style={{
                      padding: "10px 20px",
                      fontSize: "16px",
                      borderRadius: "6px",
                      border: "none",
                      backgroundColor: "#EDF1F7",
                      color: "#333333",
                      fontWeight: "bold",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    }}
                    disabled
                  >
                    <span
                      style={{
                        width: "16px",
                        height: "16px",
                        border: "2px solid #ccc",
                        borderTop: "2px solid #666",
                        borderRadius: "50%",
                        animation: "spin 1s linear infinite",
                      }}
                    ></span>
                    Loading more data...
                  </button>
                </div>
              )}
              <style>
                {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
              </style>
            </div>
            // <PropertyListing key={update} page={page} hasMore={hasMore} propertyData={propertyList} fetchPropertyList={fetchAllProperty} />
          )}
        </Container>
      </section>
      {/* <h2 className="align-items-center">This is dashboardthth</h2> */}
      <section id="footer_id" className='testimonial_section'>
        <div className='page_sub_heading'>
          <h2 className='mb-0'>Testimonials</h2>
        </div>
        <Testimonial data={data?.testimonials} />
      </section>
    </div>
  )
}
