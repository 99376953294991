import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Box, FormControl, Badge, Autocomplete, TextField } from '@mui/material';
import Divider from '@mui/material/Divider';
import { NearToDropDownPaginationList } from './NearToDropDownPaginationList';
import { getAllNearTo } from '../../api/common';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { bedTypeArray, GENDER_PREFERENCE } from '../../common/constant';

export default function Filter({ emitFilterData }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [value, setValue] = React.useState([1000, 1000])
    const [onMounted, setOnMounted] = React.useState(true)
    const [filterObject, setFilterObject] = useState({
        bed_type: '',
        near_to: [],
        gender_preference: '',
    })
    const filterInputs = []
    const filterFields = []

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [price, setPrice] = React.useState('');
    const [bedType, setBedType] = React.useState()

    const priceRangeArray = [
        '5000-1000'
    ]
    const options = ['AC Single Sharing', 'AC Double Sharing', 'AC Triple Sharing', 'Non AC Single Sharing', 'Non AC Double Sharing', 'Non AC Triple Sharing'];

    const handleChange = (event) => {
        console.log('avlue', event.target.value)
        setPrice(event.target.value);
    };
    const handleChangeBedType = (event) => {
        console.log('test data', event.target.value, bedType.label)
        setBedType(event.target.value);
    };

    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);
    const [filterCount, setFilterCount] = React.useState(0);

    const handleMultipleChange = (value) => {
        setPersonName(typeof value === 'string' ? value.split(',') : value);
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const names = [
        'TCS',
        'HIDCO',
        'AMITY'
    ];

    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }
    const handleCancel = () => {
        // Clear all filter selections
        setValue([1000, 1000]);
        setFilterObject({
            bed_type: '',
            near_to: [],
            gender_preference: '',
        })
        setFilterCount(0);
        emitFilterData('', 'filter')
        handleClose();
    };
    const handleApplyFilters = () => {
        if ((value[0] === 1000 && value[1] === 1000)) {
            setOnMounted(false)
        }

        const count = [
            !(value[0] === 1000 && value[1] === 1000) && 'Price',
            filterObject?.bed_type && 'Bed Type',
            filterObject.near_to.length > 0 && 'Near By',
            filterObject.gender_preference && 'Gender Preference'
        ].filter(Boolean).length;
        setFilterCount(count);
        if (filterObject.bed_type) {
            filterFields.push('bed_type')
            filterInputs.push(filterObject.bed_type?.value)
        }
        if (filterObject.gender_preference) {
            filterFields.push('gender_preference')
            filterInputs.push(filterObject.gender_preference)
        }
        if (filterObject.near_to?.length > 0) {
            filterFields.push('near_to')
            filterInputs.push(filterObject.near_to?.toString())
        }
        if (!(value[0] === 1000 && value[1] === 1000)) {
            filterFields.push('min_bed_price')
            filterInputs.push(`${value[0]}-${value[1]}`)
        }

        emitFilterData({ filter_fields: filterFields, filter_inputs: filterInputs }, 'filter')
        handleClose();
    };

    return (
        <div>
            <Button aria-describedby={id} onClick={handleClick} style={{ padding: '3px 10px' }}>
                <FilterAltOutlinedIcon /> <span className='text'>Filter</span>
                {filterCount > 0 && (
                    <Badge
                        badgeContent={filterCount}
                        color="secondary"
                        sx={{ position: 'absolute', top: 6, right: 54 }}
                    />
                )}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div style={{ minWidth: 290, padding: 20 }} className="popContent">
                    <h4>Filters</h4>
                    <Box style={{ minWidth: '255px', margin: '15px 0' }}>
                        <FormControl fullWidth>
                            <strong className='mb-2'>Price: {value[0]} - {value[1]}</strong>
                            <RangeSlider key={onMounted} min={1000} max={25000} value={value} onInput={(value) => setValue(value)} />
                        </FormControl>
                    </Box>
                    <Divider style={{ borderColor: '#A8A8A8' }} />
                    <Box style={{ minWidth: '255px', margin: '15px 0' }}>
                        <FormControl fullWidth>
                            <strong>Bed Type</strong>
                            <Autocomplete
                                value={filterObject?.bed_type?.label}
                                name="bed_type"
                                onChange={(event, newValue) => {
                                    setFilterObject({
                                        ...filterObject,
                                        bed_type: newValue
                                    })
                                }}
                                id="controllable-states-demo"
                                options={bedTypeArray}
                                 renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        key={option.value}
                                        {...props}
                                    >
                                        {option.label}
                                    </Box>
                                )}
                                size="small"
                                renderInput={(params) => <TextField {...params} label="" />}
                            />
                        </FormControl>
                    </Box>
                    <Divider style={{ borderColor: '#A8A8A8' }} />
                    <Box style={{ minWidth: '255px', margin: '15px 0' }}>
                        <FormControl fullWidth>
                            <strong>Gender Preference</strong>
                            <Autocomplete
                                value={filterObject?.gender_preference}
                                name="gender_preference"
                                options={Object.values(GENDER_PREFERENCE)}
                                onChange={(event, newValue) => {
                                    setFilterObject({
                                        ...filterObject,
                                        gender_preference: newValue
                                    })
                                }}
                                id="controllable-states-demo"
                                size="small"
                                renderInput={(params) => <TextField {...params} label="" />}
                            />
                        </FormControl>
                    </Box>
                    <Divider style={{ borderColor: '#A8A8A8' }} />
                    <Box style={{ minWidth: '255px', margin: '15px 0' }}>
                        <FormControl fullWidth>
                            <strong>Near By</strong>
                            <NearToDropDownPaginationList
                                searchEnabled={true}
                                label=""
                                listCall={getAllNearTo}
                                defaultValue={filterObject.near_to}
                                emitItem={(newValue) => {
                                    setFilterObject({
                                        ...filterObject,
                                        near_to: newValue
                                    })
                                }}
                                apiParams={{ limit: 10 }}
                            />
                        </FormControl>
                    </Box>
                    <Divider style={{ borderColor: '#A8A8A8' }} />
                    <div className='popButtons'>
                        <Button className="comm_popup_btn red_btn" disabled={!(filterObject.bed_type || filterObject.gender_preference || filterObject.near_to?.length || value[1] !== 1000)} onClick={handleApplyFilters}>Apply Filter</Button>
                        <Button className="comm_popup_btn yellow_btn" disabled={filterCount === 0} onClick={handleCancel}>
                            Reset Filter
                        </Button>
                    </div>
                </div>
            </Popover>
        </div>
    );
}