import React, { useState, useRef, useEffect } from "react";
import Slider from 'react-slick';
import { Modal, Box } from '@mui/material';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { getImageType } from "../Common/amenities_icon";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%', // Adjusted width for responsiveness
    maxWidth: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '10px'
};

export const PropertySlider = ({ pictures, index }) => {
    // Slider setting
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const sliderRef1 = useRef(null);
    const sliderRef2 = useRef(null);
    const [selectedPicture, setSelectedPicture] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    const [sliderValue, setSliderValue] = useState(1);
    const [sliderAutoPlay, setSliderAutoPlay] = useState(3000);
    const [sliderValudeForModal, setSliderValueForModal] = useState(0);

    useEffect(() => {
        const videoIdElement = document.getElementById('videoId');
        if (videoIdElement) {
            videoIdElement.play();
        }
    }, [sliderValue]);

    useEffect(() => {
        const videoIdElementModal = document.getElementById('videoIdModal');
        if (videoIdElementModal) {
            videoIdElementModal.play();
        }
    }, [sliderValudeForModal]);

    const handleOpenModal = (img) => {
        setSelectedPicture(img)
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedPicture()
    };

    const goToPrevSlide = () => {
        if (sliderRef1.current) {
            sliderRef1.current.slickPrev();
        }
    };

    const goToNextSlide = () => {
        if (sliderRef1.current) {
            sliderRef1.current.slickNext();
        }
    };
    const slidesToShow = pictures.length < 4 ? pictures.length : 4;
    // const handleScroll = () => {
    //     const element = document.getElementById('footer_id')?.clientHeight
    //     const cardHeight = document.getElementById('card_property')?.clientHeight
    //     const scrollBarHeight = window.innerHeight + window.scrollY
    //     if (scrollBarHeight <= cardHeight * (index + 1) && (scrollBarHeight >= cardHeight * (index - 2)))
    //         console.log(window.innerHeight + window.scrollY, document.body.offsetHeight, cardHeight * (index + 1), element)
    // }

    // // Attach scroll event listener
    // useEffect(() => {
    //     window.addEventListener("scroll", handleScroll);
    //     return () => window.removeEventListener("scroll", handleScroll);
    // }, [window.innerHeight + window.scrollY]);


    return (
        <div className="pro_slider">
            {pictures.length === 1 ? (
                getImageType(pictures[0]) ? (
                    <video
                        id="videoId"
                        autoPlay
                        loop
                        muted
                        playsInline
                        src={pictures[0].location} width="610" height="354" style={{ borderRadius: '5px', width: '100%', objectFit: 'contain' }}>
                    </video>
                ) :
                    <img src={pictures[0].location} alt={pictures[0].name} className="slider_img" onClick={() => handleOpenModal(pictures[0])} />
            ) : (
                <>
                    <Slider
                        asNavFor={nav2}
                        ref={sliderRef1}
                        arrows={false}
                        autoplay={true}
                        autoplaySpeed={sliderAutoPlay}
                        beforeChange={(current, next) => {
                            if (getImageType(pictures[next])) {
                                setSliderAutoPlay(6000)
                            } else {
                                setSliderAutoPlay(3000)
                            }
                            setSliderValue(next)
                        }}
                        afterChange={(current) => {
                            // Optional: Update navigation state here if needed
                        }}
                    >
                        {pictures.map((image, index) => (
                            <div key={index}>
                                {['mp4', 'avi', 'mkv', 'mov', 'wmv', 'webm'].includes(image?.name?.split('.').pop().toString()) ? (
                                    <video id="videoId"
                                        autoPlay
                                        loop
                                        muted
                                        playsInline src={image.location} alt={`slide-${index}`} className="main_slide_img" style={{ objectFit: 'contain' }} onClick={() => handleOpenModal(image)} >
                                    </video>
                                ) :
                                    <img src={image.location} alt={`slide-${index}`} className="main_slide_img" onClick={() => handleOpenModal(image)} />}
                            </div>
                        ))}
                    </Slider>
                    <Slider
                        className="slider_thumbnail"
                        asNavFor={nav1}
                        ref={sliderRef2}
                        slidesToShow={slidesToShow}
                        swipeToSlide={false}
                        focusOnSelect={true}
                        arrows={false}
                        infinite={false}
                    >
                        {pictures.map((image, index) => (
                            <div key={index} className={`thumbnail-slide ${index === pictures.length - 1 ? 'last-slide' : ''}`}>
                                {getImageType(image) ? (
                                    <video
                                        src={image?.location}
                                        alt={`slide-${index}`}
                                        style={{ width: '100%', height: '80px', objectFit: 'cover', borderRadius: '2px', padding: index === 0 ? '0 4px 0 0' : index === pictures.length - 1 ? '0 0 0 4px' : '0 4px' }} onClick={() => handleOpenModal(image)} >
                                    </video>) :
                                    <img
                                        src={image?.location}
                                        alt={`slide-${index}`}
                                        style={{ width: '100%', height: '80px', objectFit: 'cover', borderRadius: '2px', padding: index === 0 ? '0 4px 0 0' : index === pictures.length - 1 ? '0 0 0 4px' : '0 4px' }}
                                        onClick={() => handleOpenModal(image)}
                                    />}
                            </div>
                        ))}
                    </Slider>
                </>
            )}

            {selectedPicture && modalOpen && (
                <Modal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="crossIcon cursor-pointer position-absolute">
                                <CloseOutlined onClick={handleCloseModal} />
                            </div>
                        </div>
                        <div className="amenityImagesSlider">
                            {pictures?.length === 1 ? (
                                getImageType(selectedPicture) ? (
                                    <video
                                        id="videoIdModal"
                                        autoPlay
                                        loop
                                        muted
                                        playsInline
                                        src={selectedPicture.location} alt={selectedPicture?.name} className="slider_img" >
                                    </video>
                                ) :
                                    <img src={selectedPicture?.location} alt={selectedPicture?.name} className="slider_img" />
                            ) : (
                                <>
                                    <div className="slick-arrow custom-prev" onClick={goToPrevSlide}>
                                        <ChevronLeftOutlinedIcon className="cursor-pointer" />
                                    </div>
                                    <Slider
                                        asNavFor={sliderRef2.current}
                                        ref={sliderRef1}
                                        arrows={false}
                                        autoplay={true}
                                        autoplaySpeed={3000}
                                        beforeChange={(current, next) => {
                                            setSliderValueForModal(next)
                                        }}
                                    >
                                        <div>
                                            {
                                                getImageType(selectedPicture) ? (
                                                    <video
                                                        id="videoIdModal"
                                                        autoPlay
                                                        loop
                                                        muted
                                                        playsInline
                                                        src={selectedPicture.location} alt={`slide-${0}`} style={{ height: '400px', width: '100%' }}>
                                                    </video>
                                                ) : <img src={selectedPicture.location} alt={`slide-${0}`} style={{ height: '400px', width: '100%' }} />
                                            }
                                        </div>
                                        {pictures.map((image, index) => (
                                            image?.id !== selectedPicture?.id && (
                                                <div key={index}>
                                                    {getImageType(image) ? (
                                                        <video
                                                            id="videoIdModal"
                                                            autoPlay
                                                            loop
                                                            muted
                                                            playsInline
                                                            src={image.location} alt={`slide-${index}`} style={{ height: '400px', width: '100%' }}>
                                                        </video>) :
                                                        <img src={image.location} alt={`slide-${index}`} style={{ height: '400px', width: '100%' }} />}
                                                </div>
                                            )
                                        ))}
                                    </Slider>
                                    <div className="slick-arrow custom-next" onClick={goToNextSlide}>
                                        <ChevronRightOutlinedIcon className="cursor-pointer" />
                                    </div>
                                </>
                            )}
                        </div>
                    </Box>
                </Modal>
            )}

        </div>
    );
};
