import React, { useState, useEffect } from "react";
import FloorBedMatrix from "./floorBedMatrix";
import { getFloorName } from "../Common/amenities_icon";

function BedMatrixHome({ propertyDetails, basicDetails }) {
    const [floorRowData, setFloorRowData] = useState([]);

    useEffect(() => {
        if (propertyDetails) {     
            let sortedFloor = []
            if (propertyDetails?.floors?.length > 0) {
                const result = propertyDetails?.floors.map((el) => {
                    const res = el.name.slice(0, -2);
                    return {
                        ...el,
                        name: parseInt(res),
                        ...(el?.user !== null ? {
                            user: el.user
                        } : {
                            user: propertyDetails.user
                        })
                    }
                })
                sortedFloor = result.toSorted((a, b) => a.name > b.name ? 1 : -1).map((it) => it);
            }
            setFloorRowData(sortedFloor)
        }
    }, [propertyDetails])

    return (
        <div className="whole_bedmatrix">
            <div className="bm_body_cols">
                {floorRowData.map((item, index) => (
                    <div className="bm_single_row d-flex w-100" key={index}>
                        <div className="bm__flat">
                            <FloorBedMatrix floor={getFloorName(item?.name)} owner_id={item?.user?.id || basicDetails?.user_id} rooms={item?.rooms} basicDetails={basicDetails} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default BedMatrixHome;
