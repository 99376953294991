import React, { useEffect, useState } from 'react';
import { Container, Card, TextField, Grid, InputLabel, Box } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import Button from '@mui/material/Button';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import Swal from 'sweetalert2'
import { BlobProvider } from '@react-pdf/renderer';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import withReactContent from 'sweetalert2-react-content'

import { toast } from 'react-toastify';
import { createSchedulePropertyVisitRequest, generateOTP, verifyScheduleVisitOTP } from '../../api/schedule-property-visit';
import { useFormik } from 'formik';
import { contactNoPattern, handleKeyDown } from '../../helpers/string_helper';
import { useLocation, useNavigate } from 'react-router-dom';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { DEFAULT_DATETIME_FORMAT, PROPERTY_STATUS, SCHEDULE_PROPERTY_VISIT_OTP_TYPE, SOCKET_EVENT } from '../../common/constant';
import CountdownTimer from '../../components/Common/CountdownTimer';
import moment from 'moment/moment';
import SchedulePropertyVisitSlipPdf from '../../components/VisitRequest/SchedulePropertyVisitSlipPdf';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined'
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { socket } from '../../utils/socket';


export const ScheduleForm = () => {
    const [otp, setOtp] = useState('');
    const [otpExpiresAt, setOTPExpiresAt] = useState({
        status: false,
        phone_no: ''
    });
    const [disableResend, setDisableResend] = useState(true);
    const [scheduleLoading, setScheduleLoading] = useState(false);
    const [resendTime, setResendTime] = useState(30);
    const [isValidOTP, setIsValidOTP] = useState({
        status: false,
        phone_no: ''
    });
    const [invalidOTPerror, setInvalidOTPerror] = useState(null);
    const [socketData, setSocketData] = useState()
    const location = useLocation();
    const navigate = useNavigate();
    const sendVisitRequestHandler = async () => {
        const MySwal = withReactContent(Swal);
        const createdVisitRequest = await createPropertyScheduleRequest({ ...formik?.values, otp });
        if (createdVisitRequest)
            MySwal.fire({
                title: <i>Success</i>,
                html:
                    <span>
                        <p>
                            Your Booking is schedule at {moment(createdVisitRequest?.visit_datetime).format(DEFAULT_DATETIME_FORMAT)} at {createdVisitRequest?.property?.name}.
                            Generate the visiting slip and carry it during the time of Property Visit.`
                        </p>
                        <BlobProvider
                            document={
                                <SchedulePropertyVisitSlipPdf
                                    item={createdVisitRequest}
                                />
                            }>
                            {({ url }) => (
                                <a
                                    href={url}
                                    target='_blank'
                                    rel='noreferrer'>
                                    Download visiting slip
                                    <FileDownloadIcon
                                        titleAccess='Download Slip'
                                        fontSize={'inherit'}
                                        color='error'
                                        style={{
                                            textAlign: 'center',
                                            fontSize: '28px',
                                            cursor: 'pointer',
                                        }}
                                    />
                                </a>
                            )}
                        </BlobProvider>
                    </span>
                ,
                icon: 'success',
                confirmButtonText: 'OK',
                confirmButtonColor: '#faa61b',
            })
    }

    const gotoHome = () => {
        navigate('/')
    }
    const createPropertyScheduleRequest = async (payload) => {
        try {
            setScheduleLoading(true)
            payload = Object.fromEntries(Object.entries(payload).filter(([_k, v]) => {
                return !!v
            }))
            const response = await createSchedulePropertyVisitRequest(payload)
            toast.success(response.data.message)
            gotoHome();
            return response?.data?.data;
        } catch (e) {
            console.error(e);
            toast.error(e.response?.data?.message)
        } finally {
            setScheduleLoading(false)
        }
    }

    const initialScheduleDetails = {
        fullname: '',
        phone_no: '',
        email: '',
        property_id: location?.state?.property?.id,
        visit_datetime: ''
    }
    const formik = useFormik({
        initialValues: { ...initialScheduleDetails },
        validationSchema: Yup.object({
            fullname: Yup.string().strict(true).trim('Leading and trailing spaces is not allowed.').required('Please enter visitor full name.'),
            phone_no: Yup.string().strict(true).trim('Leading and trailing spaces is not allowed.').matches(contactNoPattern, 'Invalid contact information.').required('Please enter visitor phone number.'),
            email: Yup.string()
                .strict(true)
                .trim('Must not include leading and trailing spaces')
                .email()
                .typeError('Please Enter Valid Email')
                .optional(),
            property_id: Yup.number().typeError('Please select a property for visitation.').positive('Invalid property selected').required('Please select a property for visitation.'),
            visit_datetime: Yup.date().typeError("Given date is invalid type")
                .min(dayjs(), `Your Date of visit should be equal to or after current date ${dayjs().format(DEFAULT_DATETIME_FORMAT)}.`)
                .required('Please enter visitation date'),
        }),
        validateOnMount: true,
        onSubmit: (values) => createPropertyScheduleRequest(values)
    })

    const sendOTPHandler = async (e, type) => {
        if (!!formik.errors?.phone_no) e.preventDefault();
        try {
            const response = await generateOTP({
                type,
                phone_no: formik.values.phone_no,
            })
            toast.success(response?.data?.message)
            if (response.data.data.expiresAt) {
                setOTPExpiresAt({ status: response.data.data.expiresAt, phone_no: formik.values.phone_no });
                setOtp();
            }
        } catch (e) {
            if (e?.response?.data?.message) toast.error(e.response.data.message);
            else toast.error(e.message);
        }
    }
    const verifyScheduleVisitOTPHandler = async (otp) => {
        try {
            const response = await verifyScheduleVisitOTP({
                otp,
                phone_no: formik?.values?.phone_no,
            })
            if (response.data) {
                setIsValidOTP({
                    status: true,
                    phone_no: formik?.values?.phone_no,
                });
                setInvalidOTPerror('');
            }
            toast.success(response?.data?.message);
        } catch (e) {
            setIsValidOTP({
                status: false,
                phone_no: ''
            })            // if (e?.response?.data?.message) toast.error(e?.response?.data?.message);
            setInvalidOTPerror(e?.response?.data?.message);
        }
    }

    useEffect(() => {
        if (socket) {
            socket.on(SOCKET_EVENT.PROPERTY_STATUS_EMIT, (data) => {
                if (!socketData || (socketData?.status !== data?.data?.status?.label && socketData?.id !== data?.data?.property_id)) {
                    setSocketData({
                        status: data?.data?.status?.label,
                        id: data?.data?.property_id
                    })
                }
            });
        }
    }, [socket]);

    useEffect(() => {
        if (socketData?.id === location?.state?.property?.id && socketData?.status === PROPERTY_STATUS.ACTIVE) {
            navigate('/')
        }
    }, [socketData]);

    useEffect(() => {
        if (!location?.state?.property) gotoHome();
    }, []);

    return (
        <div className='detail_booking_form schedule_form'>
            <section className="available_rooms_listing">
                <Container>
                    <div className='form_details mb-3'>
                        <div className='page_header schedule_visit_heading mt-4 d-flex'>
                            <ArrowBackOutlinedIcon className="me-2" onClick={() => navigate(-1)} style={{ cursor: 'pointer' }} />
                            <h2>Schedule Property Visit</h2>
                        </div>
                        <Card>
                            <Grid
                                container
                                // justifyContent="center"
                                // alignItems="center"
                                spacing={2}
                            >
                                <Grid
                                    item
                                    // justifyContent="center"
                                    // alignItems="center"
                                    md={4}
                                    sm={12}
                                // spacing={2}
                                >
                                    {/* <div className='d-flex flex-column justify-content-between align-items-center'> */}
                                    <div className='icon_input'>
                                        <InputLabel htmlFor="phone" style={{ color: '#1F1F1F' }}>Enter Your Phone No.</InputLabel>
                                        <TextField
                                            id="outlined-required"
                                            placeholder="Phone"
                                            fullWidth
                                            size='small'
                                            name="phone_no"
                                            className="form-control mb-3"
                                            // label="Visitor's Contact No"
                                            type="text"
                                            disabled={scheduleLoading}
                                            onKeyDown={(e) => { handleKeyDown(e) }}
                                            onChange={(e) => {
                                                formik.setFieldValue('phone_no', e.target.value)
                                                setIsValidOTP({
                                                    status: false,
                                                    phone_no: isValidOTP.phone_no
                                                })
                                            }} onBlur={formik.handleBlur}
                                            value={formik.values.phone_no}
                                            required
                                            error={
                                                !!(formik.touched?.phone_no && formik.errors?.phone_no)
                                            }
                                            helperText={
                                                formik.touched?.phone_no && formik.errors?.phone_no
                                                    ? formik.errors?.phone_no
                                                    : ''
                                            }
                                        />
                                        {/* </div> */}
                                        {/* <div className='d-flex flex-column align-items-center'> */}
                                        <CallOutlinedIcon />
                                    </div>
                                    {/* <Button className='theme_button sign_in_button my-3' variant="contained">Send OTP</Button> */}
                                    <div className='my-3'>
                                        <div className='my-3'>
                                            <InputLabel htmlFor="otp" style={{ color: '#1F1F1F' }}>Enter OTP</InputLabel>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: 2,
                                                }}
                                            >
                                                <MuiOtpInput
                                                    // className='otp_box'
                                                    TextFieldsProps={{
                                                        size: 'small',
                                                        disabled: !(otpExpiresAt.status && otpExpiresAt.phone_no === formik.values.phone_no),
                                                    }}
                                                    value={otp}
                                                    onChange={(otp) => setOtp(otp)}
                                                    onComplete={(otp) => verifyScheduleVisitOTPHandler(otp)}
                                                    onKeyDown={(e) => { handleKeyDown(e) }}
                                                    length={6}
                                                    disabled
                                                />
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <span className='mt-2'>
                                                        {((otpExpiresAt.status && otpExpiresAt.phone_no === formik.values.phone_no) && resendTime && (!isValidOTP.status)) ?
                                                            <>
                                                                Resend OTP in <CountdownTimer seconds={resendTime} onComplete={() => {
                                                                    setDisableResend(false);
                                                                    setResendTime(0);
                                                                }} />
                                                                <p
                                                                    className='error'
                                                                >
                                                                    {invalidOTPerror}
                                                                </p>
                                                            </>
                                                            :
                                                            <p
                                                                className='error'
                                                            >
                                                                {invalidOTPerror}
                                                            </p>
                                                        }
                                                    </span>
                                                    {(!otpExpiresAt.status || otpExpiresAt.phone_no !== formik.values.phone_no) ? (<Button
                                                        className={`${!formik.errors?.phone_no ? 'yellow_gradient_btn' : 'disabled'} sign_in_button mt-2`}
                                                        variant="contained"
                                                        disabled={!!formik.errors?.phone_no || scheduleLoading}
                                                        size='small'
                                                        onClick={(e) => sendOTPHandler(e, SCHEDULE_PROPERTY_VISIT_OTP_TYPE.GENERATE)}
                                                    >
                                                        SEND OTP
                                                    </Button>)
                                                        : (<Button
                                                            className={`${!disableResend ? 'yellow_gradient_btn' : 'disabled'} sign_in_button mt-2`}
                                                            variant="contained"
                                                            disabled={disableResend}
                                                            size='small'
                                                            onClick={(e) => {
                                                                setResendTime(30);
                                                                setDisableResend(true);
                                                                sendOTPHandler(e, SCHEDULE_PROPERTY_VISIT_OTP_TYPE.RESEND)
                                                            }}
                                                        >
                                                            Resend
                                                        </Button>)}
                                                </div>
                                            </Box>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <div>
                                        <InputLabel htmlFor="name" style={{ color: '#1F1F1F' }}>Enter Your Full Name</InputLabel>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            placeholder="Full Name"
                                            fullWidth
                                            size='small'
                                            name="fullname"
                                            className="form-control mb-3"
                                            // label="Visitor's Contact No"
                                            type="text"
                                            disabled={scheduleLoading || !isValidOTP.status}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.fullname}
                                            error={
                                                !!(formik.touched?.fullname && formik.errors?.fullname)
                                            }
                                            helperText={
                                                formik.touched?.fullname && formik.errors?.fullname
                                                    ? formik.errors?.fullname
                                                    : ''
                                            }
                                        />
                                    </div>
                                    <div className='my-3'>
                                        <div className='icon_input my-3'>
                                            <InputLabel htmlFor="email" style={{ color: '#1F1F1F' }}>Enter Your Email Address</InputLabel>
                                            <TextField
                                                required
                                                id="outlined-required"
                                                placeholder="____@gmail.com"
                                                fullWidth
                                                size='small'
                                                name="email"
                                                className="form-control"
                                                type="text"
                                                disabled={scheduleLoading || !isValidOTP.status}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email}
                                                error={
                                                    !!(formik.touched?.email && formik.errors?.email)
                                                }
                                                helperText={
                                                    formik.touched?.email && formik.errors?.email
                                                        ? formik.errors?.email
                                                        : ''
                                                }
                                            />
                                            <DraftsOutlinedIcon />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <div>
                                        {/* </Grid>
                                <Grid item md={4} sm={12}> */}
                                        <InputLabel htmlFor="tenantType" style={{ color: '#1F1F1F' }}>
                                            Property Name
                                        </InputLabel>
                                        <TextField
                                            className="form-control mb-3"
                                            id="outlined-select-currency"
                                            inputProps={{ readOnly: true }}
                                            defaultValue={location?.state?.property?.name}
                                            size='small'
                                            fullWidth
                                        />
                                    </div>
                                    <div className='my-3'>
                                        <div className='my-3'>
                                            <InputLabel htmlFor="visitDetails" style={{ color: '#1F1F1F' }}>Select Your Date and Time of Visit</InputLabel>
                                            <Box display="flex" gap={2} className="schedule_date_time">
                                                <Grid item sm={12}>
                                                    {/* <InputLabel htmlFor="visitDetails">Select Your Date and Time of Visit</InputLabel> */}
                                                    {/* <Box display="flex" gap={2}> */}

                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DateTimePicker
                                                            // label={'Schedule Visit Date & Time'}
                                                            format={DEFAULT_DATETIME_FORMAT}
                                                            placeholder='DD/MM/YYYY'
                                                            disabled={scheduleLoading || !isValidOTP.status}
                                                            slotProps={{
                                                                textField: {
                                                                    name: 'visit_datetime',
                                                                    size: 'small',
                                                                    required: true,
                                                                    placeholder: moment().format(DEFAULT_DATETIME_FORMAT),
                                                                    fullWidth: true,
                                                                    onBlur: () => {
                                                                        if (!formik.touched?.joining_date) {
                                                                            formik.setFieldTouched("joining_date", true, false);
                                                                        }
                                                                    },
                                                                    onClear: () => {
                                                                        formik.setFieldValue(
                                                                            "joining_date",
                                                                            ""
                                                                        );
                                                                    },
                                                                    error:
                                                                        !!(formik.touched?.visit_datetime && formik.errors?.visit_datetime)
                                                                    ,
                                                                    helperText:
                                                                        formik.touched?.visit_datetime && formik.errors?.visit_datetime
                                                                            ? formik.errors?.visit_datetime
                                                                            : ''
                                                                }
                                                            }}
                                                            value={formik.values.visit_datetime ? dayjs(formik.values.visit_datetime) : null}
                                                            onChange={(newValue) => {
                                                                formik.setFieldTouched('visit_datetime', true)
                                                                formik.setFieldValue('visit_datetime', newValue, true)
                                                            }}
                                                            referenceDate={dayjs(new Date())}
                                                            timeSteps={{ minutes: 1 }}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                            </Box>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                            <div className="payment_btn payment_area book_payment_btn my-3 text-center">
                                <Button
                                    disabled={scheduleLoading || !(formik.isValid && (isValidOTP.status && (isValidOTP.phone_no === otpExpiresAt.phone_no)))}
                                    style={{ width: '238px' }}
                                    className={(!scheduleLoading && formik.isValid && (isValidOTP.status && isValidOTP.phone_no === otpExpiresAt.phone_no)) ? 'theme_button sign_in_button' : ''}
                                    variant="contained"
                                    onClick={sendVisitRequestHandler}>
                                    Schedule Property Visit
                                </Button>
                            </div>
                        </Card>
                    </div>
                </Container>
            </section>
        </div>
    );
};
