import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
} from 'reactstrap';
import { PropTypes } from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Spinner } from 'reactstrap'
import { getS3BaseUrl } from '../../helpers/string_helper';
import ViewDocument from './ViewDocuments';

export default function FilePreview({shouldEyeVisible=false,...props}) {
  const [fileIsImage, setFileIsImage] = useState()
  const [openPreview, setOpenPreview] = useState(false)
  const s3BaseUrl = getS3BaseUrl()
  const isImage = (src) => {
    const img = new Image();
    img.onload = function() {
      setFileIsImage(true)
    };
  
    img.onerror = function() {
      setFileIsImage(false)
    };
    img.src = src
  }
  isImage(props?.file?.id ? `${s3BaseUrl}${props?.file?.location}` : props?.file?.location)
  
  return (
    <>
        <Card
          className='mt-1 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete'
          key={`${props?.fileIndex}-file`}>
          <div className='p-2 d-flex justify-content-between'>
              <Row className='align-items-center'>
                    <Col className='col-auto'>
                      {
                        typeof fileIsImage !== 'undefined' ? fileIsImage ? (
                              <img
                                height='80'
                                className='avatar-sm rounded bg-light'
                                alt={props?.file.name}
                                src={props?.file?.id ? `${s3BaseUrl}${props?.file?.location}` : props?.file?.location}
                              />
                            ) : (
                              <InsertDriveFileIcon
                                style={{ textAlign: 'center', fontSize: '45px', cursor: 'pointer' }} fontSize={'inherit'} color='primary'
                              />
                            )
                        :
                        (
                          <Spinner size='md' color="primary" />
                        )
                      }
                    </Col>
                    <Col>
                      {/* <NavLink
                        href='#'
                        className='text-muted font-weight-bold'>
                        {props?.file.name}
                      </NavLink> */}
                        <p className='text-muted font-weight-bold text-truncate mb-0'>{props?.file.name}</p> {props?.file?.formattedSize && (<strong className="ml-1">{props?.file.formattedSize}</strong>)}
                    </Col>
              </Row>
            <p className='mb-0 align-self-center d-flex justify-content-around align-items-center gap-2'>
              {
                props?.file?.id &&
                  (<p className='mb-0'>
                    <a href={`${s3BaseUrl}${props?.file.location}`} target='_blank' download={props?.file?.name} rel="noreferrer">
                    <DownloadForOfflineIcon
                      style={{ textAlign: 'center', fontSize: '28px', cursor: 'pointer' }} fontSize={'inherit'} color='error'
                    />
                    </a>
                  </p>)
              }
              {
                props?.file?.id && !shouldEyeVisible &&
                  (<p className='mb-0'>
                    <RemoveRedEyeIcon
                      style={{ textAlign: 'center', fontSize: '28px', cursor: 'pointer' }} fontSize={'inherit'} color='error'
                      onClick={() => { setOpenPreview((p) => !p) }}
                    />
                  </p>)
              }
              {
                  typeof props?.confirmFileRemoval === 'function' &&
                    <p className='mb-0'>
                      <DeleteIcon onClick={(e) => { props?.confirmFileRemoval(e, props?.file, props?.fileIndex) }} style={{ textAlign: 'center', fontSize: '28px', cursor: 'pointer' }} fontSize={'inherit'} color='error' />
                    </p>
              }
            </p>
          </div>
      </Card>
      {
        openPreview &&
          <ViewDocument show={openPreview} onCloseClick={() => { setOpenPreview((p) => !p) }} file={props?.file} />
      }
    </>
  );
}

FilePreview.propTypes = {
  file: PropTypes.object,
  fileIndex: PropTypes.number,
  confirmFileRemoval: PropTypes.func
}