import React, { useEffect, useState } from "react";
import FloorBedMatrix from "./floorBedMatrix";
import { getFloorName } from "../Common/amenities_icon";

function BedMatrixBhk({ propertyDetails, basicDetails }) {
    const [floorRowData, setFloorRowData] = useState([]);

    useEffect(() => {
        if (propertyDetails) {
            let sortedFloor = []
            if (propertyDetails?.floors?.length > 0) {
                const result = propertyDetails?.floors.map((el) => {
                    const res = el.name.slice(0, -2);
                    return {
                        ...el,
                        name: parseInt(res),
                        flats: el?.flats?.map((flat) => {
                            return {
                                ...flat,
                                ...(flat?.user !== null ? {
                                    user: flat.user
                                } : {
                                    user: propertyDetails.user
                                })
                            }
                        })
                    }
                })
                sortedFloor = result.toSorted((a, b) => a.name > b.name ? 1 : -1).map((it) => it);
            }
            setFloorRowData(sortedFloor)
        }
    }, [propertyDetails])

    return (
        <div className="whole_bedmatrix">
            <div className="bm_body_cols">
                {floorRowData.map((item, index) => (
                    <div className="bm_single_row d-flex w-100 mb-3" key={index}>
                        {item.flats?.length > 0 && item.flats.map((fl, flIndex) => (
                            <div className="bm__flat" key={flIndex}>
                                <FloorBedMatrix flat={fl?.name} owner_id={fl?.user?.id || basicDetails?.user_id} floor={getFloorName(item?.name)} rooms={fl?.rooms} basicDetails={basicDetails} />
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}
export default BedMatrixBhk;