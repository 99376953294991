import React, { useEffect, useState } from "react";
import { Container } from '@mui/material';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { getCMSDetails } from "../../api/global-settings";
import { getS3BaseUrl } from "../../helpers/string_helper";
import { CMS_PAGES } from "../../constants/variables";

export default function AboutUs() {
    const [data, setData] = useState({})
    const fetchPageDetails = async () => {
        try {
            const resp = await getCMSDetails(CMS_PAGES.CONTACT_US);
            setData(resp.data.data)
        } catch (e) {
            console.log(e);
        }
    }
    useEffect(() => {
        fetchPageDetails();
    }, [])

    return (
        <>
            <div className='manage_enlist_property'>
                <section>
                    <Container>
                        {data?.banner_image_url &&
                            (<img src={`${getS3BaseUrl()}${data?.banner_image_url}`} alt="" style={{ maxWidth: '50%', height: 'fit-content' }} />
                            )}
                        <div className="cms_page about">
                            <div className="contact_block mt-2">
                                <h2 dangerouslySetInnerHTML={{ __html: data?.header }}></h2>
                                <ul className="list-inline">
                                    <li className="p-1"><FmdGoodOutlinedIcon /> <span dangerouslySetInnerHTML={{ __html: data?.address }}></span></li>
                                    <li className="p-1"><LocalPhoneOutlinedIcon /><span dangerouslySetInnerHTML={{ __html: data?.phone_number }}></span></li>
                                    <li className="p-1"><MailOutlinedIcon /><span dangerouslySetInnerHTML={{ __html: data?.email }}></span></li>
                                </ul>
                                {data?.content && (<div dangerouslySetInnerHTML={{ __html: data?.content }}></div>)}
                            </div>
                        </div>
                    </Container>
                </section>
            </div>
        </>
    )
} 
