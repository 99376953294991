export const PER_PAGE = [5, 10, 20, 50, 100];

export const DEFAULT_PER_PAGE_VALUE = 10;

export const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY'

export const DEFAULT_DATETIME_FORMAT = 'DD-MM-YYYY hh:mm A'

export const PORTAL = {
  TENANT_PORTAL: 'Tenant Portal',
  BACKOFFICE_PORTAL: 'Backoffice Portal',
  OWNER_PORTAL: 'Owner Portal',
};
export const PAYMENT_STATUS = {
  PAID: 'Paid',
  PENDING: 'Pending',
  DUE: 'Due',
  PROCESSING: 'Processing',
  DRAFT: 'Draft',
  PUBLISHED: 'Published'
}
export const BED_TYPES = {
  SINGLE: 'Single Sharing',
  DOUBLE: 'Double Sharing',
  TRIPLE: 'Triple Sharing',
}

export const VACANT_REQUEST_ACKNOWLEDGE_STATUS = {
  PENDING: 'Pending', // default
  ACKNOWLEDGED: 'Acknowledged',
  CANCELLED: 'Cancelled',
};

export const ACTIONS = {
  CREATE: 'Create',
  EDIT: 'Edit',
  VIEW: 'View',
  DELETE: 'Delete',
};

export const KNOWN_ROLES = {
  SUPER_ADMIN: 'Super Admin',
  OWNER: 'Owner',
  TENANT: 'Tenant',
  PROPERTY_MANAGER: 'Property Manager',
  PROSPECTING_OWNER: 'Prospecting Owner',
}

export const ALL_MODULES = {
  TENANTS: 'Tenants',
  OWNERS: 'Owners',
  PROPERTIES: 'Properties',
  COMPLAIN_TICKETS: 'Complain Tickets',
  ADD_PROPERTY_REQUESTS: 'Add Property Requests',
  MESSAGE: 'Message',
  NOTIFICATION: 'Notification',
};

export const OPERATION_TYPE = {
  CREATE: 'Create',
  UPDATE: 'Edit',
  DELETE: 'Delete',
  READ: 'Read',
  ADD_TENANT: 'Add Tenant',
  REMOVE_TENANT: 'Remove Tenant',
};

export const SCHEDULE_PROPERTY_VISIT_OTP_TYPE = {
  GENERATE: 'Generate',
  RESEND: 'Resend',
};

export const GLOBAL_SETTINGS_KEY = {
  NOTICE_PERIOD: 'notice_period',
  PRE_BOOKING_DAYS: 'pre_booking_days',
  PLATFORM_FEE: 'platform_fee',
  PAYOUT_PERCENTAGE: 'payout_percentage',
  DEPOSIT_AMOUNT: 'deposit_amount',
};

export const PROPERTY_TYPES = {
  APARTMENT: 'Apartment or Flat Style',
  PERSONAL_HOME: 'Non-Apartment Style',
};

export const options = [{
  name: 'TCS',
  value: 1,
  latitude: '56768787',
  longitude: '36783',
  address: ''
}, {
  name: 'TCS Gitanjali',
  value: 2,
  latitude: '56768787',
  longitude: '36783',
  address: ''
}
]

export const DOCUMENT_HASH_BASE_KEYS = {
  PROPERTY_REQUEST: 'property-request',
};

export const TENANT_TYPES = {
  OTHERS: 'Others',
  STUDENT: 'Student',
  WORKING_PROFESSIONAL: 'Working Professional',
}

export const GENDER_PREFERENCE = {
  GIRLS_PG: `Girls' PG`,
  BOYS_PG: `Boys' PG`,
  COLIVE_PG: 'Colive PG'
}

export const USER_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

export const SOCKET_EVENT = {
  PROPERTY_STATUS_SEND: 'property_status_send',
  PROPERTY_STATUS_EMIT: 'property_status_emit',
  BED_BOOKING_SEND: 'bed_booking_send',
  BED_BOOKING_EMIT: 'bed_booking_emit',
  FOOD_MENU_SEND: 'food_menu_send',
  FOOD_MENU_EMIT: 'food_menu_emit',
  ADD_TENANT_SEND: 'add_tenant_send',
  ADD_TENANT_EMIT: 'add_tenant_emit',
  PG_VACANT_SEND: 'pg_vacant_send',
  PG_VACANT_EMIT: 'pg_vacant_emit',
  PG_VACANT_CANCEL_SEND: 'pg_vacant_cancel_send',
  PG_VACANT_CANCEL_EMIT: 'pg_vacant_cancel_emit',
  CANCEL_BOOKING_SEND: 'cancel_booking_send',
  CANCEL_BOOKING_EMIT: 'cancel_booking_emit',
  SAME_BED_BOOKING_SEND: 'same_bed_booking_send',
  SAME_BED_BOOKING_EMIT: 'same_bed_booking_emit',
  BED_BOOKING_REFRESH_SEND: 'bed_booking_refresh_send',
  BED_BOOKING_REFRESH_EMIT: 'bed_booking_refresh_emit',
  CUSTOM_DEPOSIT_SEND: 'custom_deposit_send',
  CUSTOM_DEPOSIT_EMIT: 'custom_deposit_emit',
};

export const CAUTION_MONEY_TYPE = {
  EQUAL_TO_BED_RATE: 'equal_to_bed_rent',
  FIXED_RATE: 'fixed_rate',
  CUSTOM: 'custom'
}

export const PROPERTY_STATUS = {
  ACTIVE: 'Active', // DEFAULT
  LIVE: 'Live',
  SUSPENDED: 'Suspended',
  CLOSED: 'Closed',
};

export const PAYMENT_GATEWAY = {
  CASHFREE: 'cashfree',
  PHONEPE: 'phonepe',
};

export const bedTypeArray = [
  {
    label: 'AC Single Sharing',
    value: 'AC Single Sharing'
  },
  {
    label: 'AC Double Sharing',
    value: 'AC Double Sharing'
  },
  {
    label: 'AC Triple Sharing',
    value: 'AC Triple Sharing'
  },
  {
    label: 'Non AC Single Sharing',
    value: 'NC Single Sharing'
  },
  {
    label: 'Non AC Double Sharing',
    value: 'NC Double Sharing'
  },
  {
    label: 'Non AC Triple Sharing',
    value: 'NC Triple Sharing'
  },
]

export const ROOM_TYPES = {
  AC: 'AC',
  NON_AC: 'NC',
};