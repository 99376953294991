// import axios from "axios"

// const instance = axios.create({
//   baseURL: process.env.REACT_APP_API_URL,
// })

// // const instance = createAxiosInstance()

// instance.interceptors.request.use(function (config) {
//   const token = localStorage.getItem('token')
//   config.headers.Authorization =  token
     
//   return config
// })
// export default instance
import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

// Request interceptor
// axiosInstance.interceptors.request.use(
//   (config) => {
//     const accessToken = localStorage.getItem("token-tenant")

//     if (accessToken) {
//       if (config.headers) config.headers.Authorization = accessToken
//     }
//     return config
//   },
//   (error) => {

//     return Promise.reject(error)
//   }
// )

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error)
  }
)
export default axiosInstance