import React, { useState, useEffect } from "react";
import WebsiteApp from "../../assets/images/web_app.svg";

const DashboardInfoCards = ({data}) => {
    const dashInfo = data.map((text) => ({image: WebsiteApp, text: <span dangerouslySetInnerHTML={{__html: text}}></span>}));
      const [currentPage, setCurrentPage] = useState(0);
      const cardsPerPage = 4;
      const totalPages = Math.ceil(dashInfo.length / cardsPerPage);
      
      useEffect(() => {
        const autoSlide = setInterval(() => {
          handleNext();
        }, 6000);
        return () => clearInterval(autoSlide);
      }, [currentPage]);
      
      const handleNext = () => {
        if (currentPage < totalPages - 1) {
          setCurrentPage(currentPage + 1);
        } else {
          setCurrentPage(0); // Loop back to the first slide
        }
      };
      
      const handlePrev = () => {
        if (currentPage > 0) {
          setCurrentPage(currentPage - 1);
        } else {
          setCurrentPage(totalPages - 1); // Loop back to the last slide
        }
      };
      
      const handleDotClick = (index) => {
        setCurrentPage(index);
      };
      
      return (
        <div className="slider-wrapper">
          <div className="slider">
            <div
              className="home_info_cards card-container"
              style={{
                transform: `translateX(-${currentPage * 100}%)`,
                transition: 'transform 0.5s ease-in-out',
              }}
            >
              {dashInfo.map((item, index) => (
                <div className="single_card slider_card" key={index}>
                  <div className="info_bg">
                    <img src={item.image} alt="dash_info" width="100" height="100" />
                  </div>
                 <span className="slider_card_wrap"> {item.text}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="controls">
            <button className="prev" onClick={handlePrev}>
              &#10094;
            </button>
      
            <div className="dots">
              {Array.from({ length: totalPages }).map((_, index) => (
                <span
                  key={index}
                  className={`dot ${currentPage === index ? 'active' : ''}`}
                  onClick={() => handleDotClick(index)}
                ></span>
              ))}
            </div>
      
            <button className="next" onClick={handleNext}>
              &#10095;
            </button>
          </div>
        </div>
      );
    }

export default DashboardInfoCards;