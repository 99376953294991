import React, { useCallback, useEffect, useState } from "react";
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import Photo from "../../assets/images/photo-icon.svg";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Button } from 'reactstrap';
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs'
import Tooltip from "@mui/material/Tooltip";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AmenitiesSlider from "./amenitiesSlider";
import { fetchBedType, selectAmenitiesIcon } from "../Common/amenities_icon";
import { getS3BaseUrl } from "../../helpers/string_helper";
import { socket } from "../../utils/socket";
import { CAUTION_MONEY_TYPE, DEFAULT_DATE_FORMAT, PROPERTY_STATUS, SOCKET_EVENT } from "../../common/constant";
import { debounce } from "@mui/material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%', // Adjusted width for responsiveness
    maxWidth: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '10px'
};

function FloorBedMatrix(props) {
    const [amenityOpen, setAmenityOpen] = useState(false);
    const [selectedAmenities, setSelectedAmenities] = useState([]);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const amenityHandleOpen = () => setAmenityOpen(true);
    const amenityHandleClose = () => setAmenityOpen(false);
    const [roomData, setRoomData] = useState();
    const s3BaseUrl = getS3BaseUrl();
    const [socketData, setSocketData] = useState();
    const [pgVacantSocketData, setPgVacantSocketData] = useState();
    const [cancelBookingSocket, setCancelBookingSocket] = useState();
    const [bookingSameBed, setBookingSameBed] = useState();
    const [bookBedRefresh, setBookBedRefresh] = useState();
    const [customDepositSocket, setCustomDepositSocket] = useState();
    const [propertyBasicDetails, setBasicPropertyDetails] = useState();

    const navigate = useNavigate();
    const handleBookClick = (room) => {
        navigate('/book-room', {
            state: {
                roomData: JSON.stringify({ ...room, owner_id: props?.owner_id })
            },
        });
    };

    useEffect(() => {
        if (props?.rooms) {
            const result = props?.rooms.map((item) => {
                return {
                    ...item,
                    documents: item?.documents?.length > 0 ? item?.documents.map((img) => {
                        return {
                            ...img,
                            location: `${s3BaseUrl}${img.location}`
                        }
                    }) : []
                }
            })
            setRoomData(result)
            setBasicPropertyDetails(props?.basicDetails)
        }
    }, [props?.rooms])

    useEffect(() => {
        if (socket) {
            socket.on(SOCKET_EVENT.ADD_TENANT_EMIT, (data) => {
                if ((!socketData || socketData?.bedData?.id !== data?.bedData?.id) && data?.property_id === props?.basicDetails?.id && data?.status === PROPERTY_STATUS.LIVE) {
                    setSocketData(data)
                }
            });
            socket.on(SOCKET_EVENT.BED_BOOKING_REFRESH_EMIT, (data) => {
                if ((!bookBedRefresh || bookBedRefresh?.bed?.id !== data?.bed?.id) && (data?.property_id === props?.basicDetails?.id)) {
                    setBookBedRefresh(data)
                    const roomFilter = props?.rooms?.find((item) => item?.id === data?.bed?.room_id)
                    const bedData = roomFilter?.beds?.find((bed) => bed?.id === data?.bed?.id)
                    if (roomFilter && bedData) {
                        updateRoomData(data?.bed?.user ? { ...data?.bed, bed_id: data?.bed?.id, vacant_requests: bedData?.vacant_requests } : { ...bedData, is_temporarily: false, expiry_at: null, bed_id: data?.bed?.id, room_id: data?.bed?.room_id }, 'booking')
                    }
                }
            })
            socket.on(SOCKET_EVENT.PG_VACANT_EMIT, (data) => {
                if ((!pgVacantSocketData || pgVacantSocketData?.bed_id !== data?.bed_id) && data?.property_id === props?.basicDetails?.id) {
                    setPgVacantSocketData(data)
                }
            });
            socket.on(SOCKET_EVENT.CANCEL_BOOKING_EMIT, (data) => {
                if ((!cancelBookingSocket || cancelBookingSocket?.id !== data?.id) && data?.property_id === props?.basicDetails?.id && data?.status === PROPERTY_STATUS.LIVE) {
                    setCancelBookingSocket(data)
                }
            })
            socket.on(SOCKET_EVENT.BED_BOOKING_EMIT, (data) => {
                if ((!bookingSameBed || bookingSameBed?.bed_id !== data?.bed_id) && data?.property_id === props?.basicDetails?.id && data?.status === PROPERTY_STATUS.LIVE) {
                    setBookingSameBed(data)
                }
            })
            socket.on(SOCKET_EVENT.CUSTOM_DEPOSIT_EMIT, (data) => {
                if ((!customDepositSocket || customDepositSocket?.property_id !== data?.property_id) && data?.property_id === props?.basicDetails?.id && data?.status === PROPERTY_STATUS.LIVE) {
                    setCustomDepositSocket(data)
                    if (!data?.bed_id && !data?.caution_money) {
                        const mapProperty = propertyBasicDetails ? propertyBasicDetails : props?.basicDetails
                        setBasicPropertyDetails({ ...mapProperty, caution_money_type: data?.caution_money_type, ...(data?.caution_money_type === CAUTION_MONEY_TYPE.FIXED_RATE && { fixed_rate: data?.fixed_rate }) })
                    }
                    if (data?.caution_money_type === CAUTION_MONEY_TYPE.CUSTOM && data?.caution_money && data?.bed_id) {
                        const mapRoom = roomData ? roomData : props?.rooms
                        const roomFilter = mapRoom?.find((item) => item?.id === data?.room_id)
                        const bedData = roomFilter?.beds?.find((bed) => bed?.id === data?.bed_id)
                        if (roomFilter && bedData) {
                            updateRoomData({ ...bedData, caution_money: data?.caution_money, bed_id: data?.bed_id, room_id: data?.room_id }, 'booking')
                        }
                    }
                }
            })
        }
    }, [socket]);

    useEffect(() => {
        if (bookingSameBed?.bed_id && bookingSameBed?.property_id === props?.basicDetails?.id && bookingSameBed?.status === PROPERTY_STATUS.LIVE) {
            const roomFilter = props?.rooms?.find((item) => item?.id === bookingSameBed?.room_id)
            const bedData = roomFilter?.beds?.find((bed) => bed?.id === bookingSameBed?.bed_id)
            if (roomFilter && bedData) {
                updateRoomData({ ...bedData, bed_id: bedData?.id, is_temporarily: true, room_id: bookingSameBed?.room_id, expiry_at: bookingSameBed?.expiry_at }, 'booking')
                refresh({ ...bedData, bed_id: bedData?.id, is_temporarily: false, room_id: bookingSameBed?.room_id, expiry_at: null })
            }
        }
    }, [bookingSameBed])

    const refresh = useCallback(
        debounce((data) => {
            updateRoomData(data, 'refresh')
        }, 900000),
        []
    );

    const updateRoomData = (data, type) => {
        const mapRoomData = roomData ?? props?.rooms
        const result = mapRoomData?.map((room) => {
            if (room.id === data?.bed?.room?.id || room.id === data?.room_id) {
                const updatedBed = room?.beds.map((bed) => {
                    if (bed.id === data?.bed_id) {
                        return {
                            ...bed,
                            ...(type === 'booking' && {
                                ...data
                            }),
                            ...(type === 'refresh' && (bed?.vacant_requests?.length > 0 ? true : !bed?.user) && {
                                ...data
                            }),
                            ...(type === 'add' && {
                                vacant_requests: [{
                                    leaving_date: data.leaving_date,
                                    note: data.note,
                                    tenant_id: data.tenant_id,
                                }]
                            }),
                            ...(type === 'cancel' && {
                                ...(bed?.vacant_requests?.length > 0 ? {
                                    advance_booking_user: null
                                } : {
                                    user: null,
                                }),
                                booking_date: null,
                            })
                        }
                    }
                    else {
                        return bed
                    }
                })
                return {
                    ...room,
                    beds: updatedBed
                }
            }
            else {
                return room
            }
        })
        setRoomData(result)
    }

    useEffect(() => {
        if (socketData?.bedData?.id && roomData?.length > 0 && socketData?.property_id === props?.basicDetails?.id) {
            const result = roomData?.map((room) => {
                if (room.id === socketData?.bedData?.room_id) {
                    const updatedBed = room?.beds.map((bed) => {
                        if (bed.id === socketData?.bedData?.id) {
                            return {
                                ...socketData?.bedData
                            }
                        }
                        else {
                            return bed
                        }
                    })
                    return {
                        ...room,
                        beds: updatedBed
                    }
                }
                else {
                    return room
                }
            })
            setRoomData(result)
        }
    }, [socketData])

    useEffect(() => {
        if (pgVacantSocketData?.bed_id && roomData?.length > 0 && pgVacantSocketData?.property_id === props?.basicDetails?.id) {
            updateRoomData(pgVacantSocketData, 'add')
        }
    }, [pgVacantSocketData])


    useEffect(() => {
        if (cancelBookingSocket?.bed_id && roomData?.length > 0 && cancelBookingSocket?.property_id === props?.basicDetails?.id) {
            updateRoomData(cancelBookingSocket, 'cancel')
        }
    }, [cancelBookingSocket])

    return (
        <div className="floorBedMatrix">
            <h5>{props?.flat ? (
                <><span className="flat_color"><DomainOutlinedIcon /> {props.floor === '0th' ? 'Ground' : props.floor} Floor</span><span className="floor_color"> ({props?.flat})</span></>)
                : (<span className="flat_color"><DomainOutlinedIcon /> Floor {props.floor === '0th' ? 'Ground' : props.floor}</span>)
            }
            </h5>
            <div className="mainCardBlock d-flex">
                {roomData?.length > 0 && roomData?.map((item, index) => (
                    <div className="card" key={index}>
                        <div className="fbmRoom d-flex align-items-start">
                            <div className="room_ico"><HotelOutlinedIcon className="me-2" /></div>
                            <div className="fbmRoomDetail">
                                <h5>{item.name}</h5>
                                <p>{item.room_type.name}, {fetchBedType(item.bed_count)}</p>
                            </div>
                            {(item.amenities?.length > 0 || item?.documents?.length > 0) && (
                                <div className="d-flex justify-content-end" style={{ marginLeft: 'auto' }}>
                                <Tooltip title="picture & amenities">
                                    <img src={Photo} alt={Photo} onClick={() => {
                                        setSelectedAmenities(item.amenities)
                                        setSelectedDocuments(item.documents)
                                        amenityHandleOpen()
                                    }} className="cursor-pointer ms-3 fbRoom_img" />
                                </Tooltip>
                                </div>
                            )}
                        </div>
                        {item.beds.map((bed, index) => (
                            <div className="fbmRoombed" key={index}>
                                <div className="d-flex align-items-center justify-content-between fbmRoomBedDetail">
                                    <h5>{bed.name}</h5>
                                    {(bed.vacant_requests?.length > 0 && bed.vacant_requests[0]?.tenant_id === bed?.user?.tenant?.id) && (
                                        dayjs(dayjs(bed.vacant_requests[0]?.leaving_date).format(DEFAULT_DATE_FORMAT)).isBefore(dayjs().format(DEFAULT_DATE_FORMAT)) && (
                                            bed?.is_temporarily && (bed?.expiry_at ? dayjs(bed?.expiry_at).isAfter(dayjs()) : true) ? (
                                                <Button className='book_btn not_booked_btn' variant="contained"> <span> Temporarily Unavailable </span></Button>
                                            ) : (
                                                <Button className='book_btn booked_btn' variant="contained">
                                                    <span onClick={() => handleBookClick({
                                                        pictures: item.documents,
                                                        room_type: `${item.room_type.name} ${fetchBedType(item.bed_count)}`,
                                                        bed: { bed, flatNo: props.flat, floorNo: props.floor, roomNo: item.name, room_id: item?.id },
                                                        basicDetails: propertyBasicDetails
                                                    })}
                                                    >Book</span>
                                                </Button>
                                            )
                                        )
                                    )}
                                    {(bed.vacant_requests?.length > 0 && bed.vacant_requests[0]?.tenant_id === bed?.user?.tenant?.id) ? (
                                        (bed.user?.id && !dayjs(dayjs(bed.vacant_requests[0]?.leaving_date).format(DEFAULT_DATE_FORMAT)).isBefore(dayjs(dayjs().format(DEFAULT_DATE_FORMAT)))) && (
                                            <div className="d-flex align-items-center gap-1">
                                                <Button className='book_btn not_booked_btn' variant="contained"> <span> Booked by a {bed?.user?.tenant.type}</span></Button>
                                                {bed?.is_temporarily && (bed?.expiry_at ? dayjs(bed?.expiry_at).isAfter(dayjs()) : true) ? (
                                                    <Button className='book_btn not_booked_btn' variant="contained"> <span> Temporarily Unavailable </span></Button>
                                                ) : (!bed?.advance_booking_user &&
                                                    <Button
                                                        className='book_btn booked_btn' variant="contained"
                                                    > <span onClick={() => handleBookClick({
                                                        pictures: item.documents,
                                                        room_type: `${item.room_type.name} ${fetchBedType(item.bed_count)}`,
                                                        bed: { bed, flatNo: props.flat, floorNo: props.floor, roomNo: item.name, room_id: item?.id },
                                                        basicDetails: propertyBasicDetails,
                                                        leaving_date: bed.vacant_requests[0]?.leaving_date
                                                    })}

                                                    >Book</span></Button>
                                                )}
                                            </div>
                                        )
                                    ) : bed.user?.id && (
                                        <Button className='book_btn not_booked_btn' variant="contained"> <span> Booked by a {bed?.user?.tenant.type}</span></Button>
                                    )}
                                    {!bed.user?.id && (
                                        bed?.is_temporarily && (bed?.expiry_at ? dayjs(bed?.expiry_at).isAfter(dayjs()) : true) ? (
                                            <Button className='book_btn not_booked_btn' variant="contained"> <span> Temporarily Unavailable </span></Button>
                                        ) : (
                                            !bed?.advance_booking_user &&
                                            <Button className='book_btn booked_btn' variant="contained">
                                                <span onClick={() => handleBookClick({
                                                    pictures: item.documents,
                                                    room_type: `${item.room_type.name} ${fetchBedType(item.bed_count)}`,
                                                    bed: { bed, flatNo: props.flat, floorNo: props.floor, roomNo: item.name, room_id: item?.id },
                                                    basicDetails: propertyBasicDetails
                                                })}>Book</span>
                                            </Button>)
                                    )}
                                </div>
                                {!bed?.advance_booking_user && (bed.vacant_requests?.length > 0 && bed.vacant_requests[0]?.tenant_id === bed?.user?.tenant?.id) ? (
                                    (bed.user?.id && !dayjs(dayjs(bed.vacant_requests[0]?.leaving_date).format(DEFAULT_DATE_FORMAT)).isBefore(dayjs(dayjs().format(DEFAULT_DATE_FORMAT)))) && (<div>
                                        <span style={{ display: 'block', fontSize: '14px', fontWeight: '600', color: '#ED3542' }}>₹ {bed.price}</span>
                                        <span style={{ display: 'block', fontSize: '12px', color: '#6d6e6f' }}>
                                            Available from {dayjs(bed.vacant_requests[0]?.leaving_date).add(1, 'day').format('DD-MM-YYYY')}</span>
                                    </div>)
                                ) : bed.user?.id && (<div>
                                    <span style={{ display: 'block', fontSize: '14px', fontWeight: '600', color: '#ED3542' }}>₹ {bed.price}</span>
                                </div>)}
                                {(!bed.user?.id && (<span style={{ fontSize: '14px', fontWeight: '600', color: '#ED3542' }}>₹ {bed.price}</span>))}
                                {(bed.vacant_requests?.length > 0 && bed.vacant_requests[0]?.tenant_id === bed?.user?.tenant?.id) && (
                                    dayjs(dayjs(bed.vacant_requests[0]?.leaving_date).format(DEFAULT_DATE_FORMAT)).isBefore(dayjs(dayjs().format(DEFAULT_DATE_FORMAT))) && (<span style={{ fontSize: '12px', color: '#ED3542' }}>₹ {bed.price}</span>))}

                            </div>
                        ))}
                    </div>
                ))}
            </div>

            {/* Amenity Modal open */}
            {amenityOpen && (
                <Modal
                    open={amenityOpen}
                    onClose={amenityHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} className="tenantRemove">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="crossIcon cursor-pointer position-absolute">
                                <CloseOutlinedIcon onClick={amenityHandleClose} />
                            </div>
                        </div>
                        {selectedDocuments?.length > 0 && (
                            <div className={selectedAmenities?.length > 0 ? "amenityImagesSlider mb-3" : "amenityImagesSlider"}>
                                <AmenitiesSlider files={selectedDocuments} />
                            </div>
                        )}
                        {selectedAmenities?.length > 0 && (
                            <div className="detail_block amenityCustomBox py-0">
                                <h6 className="block_heading mb-2">Amenities</h6>
                                <ul className="custom_amenties_options d-flex flex-wrap ps-0 mb-0 gap-2" style={{ listStyle: 'none' }}>
                                    {(selectedAmenities && selectedAmenities?.length > 0) && selectedAmenities.map((item, index) => (
                                        <li className="position-relative d-flex me-1" key={index + 1} style={{
                                            color: '#4c4343'
                                        }}>
                                            {selectAmenitiesIcon(item?.toLowerCase())} <div className="amenity_item ms-1">{item}</div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </Box>
                </Modal>
            )}
        </div>
    )
}

export default FloorBedMatrix;