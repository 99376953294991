import React, { useEffect, useState } from 'react';
import { Container, Card, TextField, Grid, InputLabel, Box, Autocomplete, OutlinedInput, InputAdornment, IconButton, FormHelperText, FormControlLabel, Checkbox } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useNavigate } from 'react-router-dom';
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined';
import SuitableTagsDesc from "../../components/Common/suitableTagsDescription";
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import AddressComponent from "../../components/Common/Address";
import { useLocation } from 'react-router-dom';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import OtherHousesOutlinedIcon from '@mui/icons-material/OtherHousesOutlined';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import { CallOutlined, CheckBox, DraftsOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { generateOTP, verifyScheduleVisitOTP } from '../../api/schedule-property-visit';
import { CAUTION_MONEY_TYPE, PAYMENT_GATEWAY, PAYMENT_STATUS, PROPERTY_STATUS, SCHEDULE_PROPERTY_VISIT_OTP_TYPE, SOCKET_EVENT, TENANT_TYPES, USER_STATUS } from '../../common/constant';
import { contactNoPattern, getS3BaseUrl, handleKeyDown } from '../../helpers/string_helper';
import CountdownTimer from '../../components/Common/CountdownTimer';
import dayjs from 'dayjs';
import { AsterikLabel } from '../../components/Common/AsterikLabel';
import { bookingBedForTenant } from '../../api/property';
import { cashfree } from '../../utils/payment';
import { socket } from '../../utils/socket';
import { FoodMenuDocumentSlider } from '../../components/Property/foodMenuSlider';
import { verifyPayment } from '../../api/payment';
import PaymentModeSelector from '../../common/PaymentModeSelector';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const FileButton = styled(Button)({

    backgroundColor: '#ECECEC',
    alignItems: 'center',
    color: '#565656',
    border: "1px solid #B1B1B1",
    '&:hover': {
        backgroundColor: '#ECECEC',
        color: '#565656',
        border: '1px solid #B1B1B1',
    },
});

const BookForm = () => {
    const location = useLocation();
    const [propertyDetails, setPropertyDetails] = useState(location.state?.propertyDetails);
    const [fileName, setFileName] = useState('No file chosen');
    const [anchorEl, setAnchorEl] = useState(null);
    const [isFoodMenu, setIsFoodMenu] = useState(false);
    const [foodMenuFiles, setFoodMenuFiles] = useState();
    const [isLoading, setIsLoading] = useState({ phonePe: false, cashfree: false });
    const [response, setResponse] = useState()
    const [otp, setOtp] = useState('');
    const [otpExpiresAt, setOTPExpiresAt] = useState({
        status: false,
        contact_number: ''
    });
    const [disableResend, setDisableResend] = useState(true);
    const [resendTime, setResendTime] = useState(30);
    const [socketData, setSocketData] = useState()
    const [isValidOTP, setIsValidOTP] = useState({
        status: false,
        contact_number: ''
    });
    const [invalidOTPerror, setInvalidOTPerror] = useState(null);
    const [preBookingDays, setPreBookingDays] = useState()
    const [addTenantSocket, setAddTenantSocket] = useState()
    const [foodDocument, setFoodDocument] = useState();
    const s3BaseUrl = getS3BaseUrl()
    const [customDepositSocket, setCustomDepositSocket] = useState();
    const minDate = propertyDetails?.basicDetails?.leaving_date ? dayjs(propertyDetails?.basicDetails?.leaving_date) : dayjs().subtract(1, 'days')
    const currentDate = propertyDetails?.basicDetails?.leaving_date ? dayjs(propertyDetails?.basicDetails?.leaving_date).add(1, 'days') : dayjs()
    const [accpetPolicy, setAcceptPolicy] = useState(false)
    // Password show/hide
    const [showPassword, setShowPassword] = useState({});
    const handleClickShowPassword = (key) => {
        setShowPassword((show) => ({ ...show, [key]: !showPassword[key] }))
    };

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    useEffect(() => {
        if (location?.state?.propertyDetails) {
            const noticePeriodDate = dayjs().add(propertyDetails?.globalData?.pre_booking?.payload?.value, propertyDetails?.globalData?.pre_booking?.payload?.unit)
            setPreBookingDays({
                date: noticePeriodDate,
                preBookingValue: propertyDetails?.globalData?.pre_booking?.payload
            })
        } else {
            window.history.pushState(null, null, document.URL)
            navigate("/")
        }
    }, [location.state?.propertyDetails])

    useEffect(() => {
        if (socket) {
            socket.on(SOCKET_EVENT.PROPERTY_STATUS_EMIT, (data) => {
                if (!socketData || (socketData?.status !== data?.data?.status?.label && socketData?.id !== data?.data?.property_id)) {
                    setSocketData({
                        status: data?.data?.status?.label,
                        id: data?.data?.property_id
                    })
                }
            });
            socket.on(SOCKET_EVENT.ADD_TENANT_EMIT, (data) => {
                if ((!addTenantSocket || addTenantSocket?.bedData?.id !== data?.bedData?.id) && data?.property_id === propertyDetails?.basicDetails?.basicDetails?.id && data?.bedData?.id === propertyDetails?.basicDetails?.bed?.bed?.id && data?.status === PROPERTY_STATUS.LIVE) {
                    setAddTenantSocket(data)
                    navigate('/property-detail', {
                        state: {
                            propertyId: JSON.stringify(propertyDetails?.basicDetails?.basicDetails)
                        }
                    })
                }
            });

            socket.on(SOCKET_EVENT.BED_BOOKING_EMIT, (data) => {
                if ((!addTenantSocket || addTenantSocket?.bed_id !== data?.bed_id) && data?.property_id === propertyDetails?.basicDetails?.basicDetails?.id && data?.bed_id === propertyDetails?.basicDetails?.bed?.bed?.id && data?.status === PROPERTY_STATUS.LIVE) {
                    setAddTenantSocket(data)
                    navigate('/property-detail', {
                        state: {
                            propertyId: JSON.stringify(propertyDetails?.basicDetails?.basicDetails)
                        }
                    })
                }
            });
            socket.on(SOCKET_EVENT.FOOD_MENU_EMIT, (data) => {
                if ((!foodDocument || foodDocument?.property_id !== data?.data?.property_id) && data?.data?.status === PROPERTY_STATUS.LIVE && propertyDetails?.basicDetails?.basicDetails?.id === data?.data?.property_id) {
                    setFoodDocument({
                        documents: data?.data?.documents,
                        property_id: data?.data?.property_id,
                        status: data?.data?.status
                    })
                }
            });

            socket.on(SOCKET_EVENT.CUSTOM_DEPOSIT_EMIT, (data) => {
                if ((!customDepositSocket || customDepositSocket?.property_id !== data?.property_id) && data?.property_id === propertyDetails?.basicDetails?.basicDetails?.id && data?.status === PROPERTY_STATUS.LIVE) {
                    setCustomDepositSocket(data)
                }
            })
        }
    }, [socket]);

    useEffect(() => {
        if (propertyDetails?.basicDetails?.basicDetails?.id === foodDocument?.property_id) {
            const food_menu_document = []
            let isNewFoodMenu = false
            if (foodDocument?.documents?.length > 0) {
                foodDocument?.documents?.forEach((menu) => {
                    if (menu?.size) {
                        isNewFoodMenu = true
                        const filterDocument = propertyDetails?.basicDetails?.basicDetails?.food_menu_documents?.find((item) => item?.id === menu?.id)
                        if (!filterDocument) {
                            food_menu_document.push({ ...menu, location: `${s3BaseUrl}${menu?.location}` })
                        }
                    }
                })
            }

            const documents = isNewFoodMenu && propertyDetails?.basicDetails?.basicDetails?.food_menu_documents?.length > 0 ? [...propertyDetails?.basicDetails?.basicDetails?.food_menu_documents, ...food_menu_document] : (isNewFoodMenu ? food_menu_document : foodDocument?.documents)
            if (isFoodMenu && foodMenuFiles?.length > 0) {
                setFoodMenuFiles(documents)
                documents?.length === 0 && setIsFoodMenu(false)
            }
            setPropertyDetails({
                ...propertyDetails,
                basicDetails: {
                    ...propertyDetails?.basicDetails,
                    basicDetails: {
                        ...propertyDetails?.basicDetails?.basicDetails,
                        food_menu_documents: documents
                    },
                }
            })
        }

    }, [foodDocument])

    useEffect(() => {
        if (socketData?.id === propertyDetails?.basicDetails?.basicDetails?.id && socketData?.status === PROPERTY_STATUS.ACTIVE) {
            navigate('/')
        }
    }, [socketData])

    useEffect(() => {
        if (customDepositSocket && propertyDetails?.basicDetails?.basicDetails?.id === customDepositSocket?.property_id) {
            let deposit_amount = 0
            if (!customDepositSocket?.bed_id) {
                if (customDepositSocket?.caution_money_type === CAUTION_MONEY_TYPE.FIXED_RATE) {
                    deposit_amount = deposit_amount + parseInt(customDepositSocket?.fixed_rate)
                } else if (customDepositSocket?.caution_money_type === CAUTION_MONEY_TYPE.EQUAL_TO_BED_RATE) {
                    deposit_amount = deposit_amount + parseInt(propertyDetails?.basicDetails?.bed?.bed?.price)
                } else if (customDepositSocket?.caution_money_type === CAUTION_MONEY_TYPE.CUSTOM && !customDepositSocket?.caution_money) {
                    deposit_amount = deposit_amount + (propertyDetails?.basicDetails?.bed?.bed?.caution_money > 0 ? parseInt(propertyDetails?.basicDetails?.bed?.bed?.caution_money) : parseInt(propertyDetails?.basicDetails?.bed?.bed?.price))
                }

                setPropertyDetails({
                    ...propertyDetails,
                    totalPayableAmount: (propertyDetails?.totalPayableAmount - propertyDetails?.globalData?.deposit_amount) + deposit_amount,
                    globalData: {
                        ...propertyDetails?.globalData,
                        deposit_amount: deposit_amount
                    }
                })
            }
            if (customDepositSocket?.caution_money_type === CAUTION_MONEY_TYPE.CUSTOM && customDepositSocket?.bed_id === propertyDetails?.basicDetails?.bed?.bed?.id) {
                deposit_amount = deposit_amount + parseInt(customDepositSocket?.caution_money)
                setPropertyDetails({
                    ...propertyDetails,
                    totalPayableAmount: (propertyDetails?.totalPayableAmount - propertyDetails?.globalData?.deposit_amount) + deposit_amount,
                    globalData: {
                        ...propertyDetails?.globalData,
                        deposit_amount: deposit_amount
                    },
                    basicDetails: {
                        ...propertyDetails?.basicDetails,
                        bed: {
                            ...propertyDetails?.basicDetails?.bed,
                            bed: {
                                ...propertyDetails?.basicDetails?.bed?.bed,
                                caution_money: customDepositSocket?.caution_money
                            }
                        }
                    }
                })
            }
        }
    }, [customDepositSocket])

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFileName(file ? file.name : 'No file chosen');
    };
    const navigate = useNavigate();

    const handleHiddenList = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const toggleFoodMenu = () => {
        setIsFoodMenu(!isFoodMenu)
    }

    const initialBookingDetails = {
        joining_date: '',
        name: '',
        contact_number: '',
        email: '',
        type: '',
        address: '',
        documents: '',
        password: '',
        confirm_password: '',
    }
    const passwordPattern = /^(?!.*[\s])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,16}$/;
    const formik = useFormik({
        initialValues: { ...initialBookingDetails },
        validationSchema: Yup.object({
            name: Yup.string().strict(true).trim('Leading and trailing spaces is not allowed.').required('Please enter name.'),
            contact_number: Yup.string().strict(true).trim('Leading and trailing spaces is not allowed.').matches(contactNoPattern, 'Invalid contact information.').required('Please enter phone number.'),
            email: Yup.string()
                .strict(true)
                .trim('Must not include leading and trailing spaces')
                .email()
                .typeError('Please Enter Valid Email')
                .optional(),
            address: Yup.string().strict(true).trim('Leading and trailing spaces is not allowed.').required('Please enter address.'),
            type: Yup.string().required('Please select tenant type.'),
            joining_date: Yup.date().typeError("Given date is invalid type")
                .min(dayjs(minDate), `Your Date of joining should be equal to or after current date ${dayjs(currentDate).format('DD/MM/YYYY')}.`)
                .max(dayjs(preBookingDays?.date), `Pre booking can be done for maximum ${preBookingDays?.preBookingValue?.value} ${preBookingDays?.preBookingValue?.unit}.`)
                .required('Please enter joining date'),
            password: Yup.string().required('Please Enter Your Password').matches(passwordPattern, "Password should be 8 character long with maximum 16 character of one capital one small one digit and one special case without whitespace"),
            confirm_password: Yup.string().oneOf([Yup.ref('password')], 'Both the passwords should match.').required('Please Enter Your Confirm Password'),
        }),
        validateOnMount: true,
    })

    const sendOTPHandler = async (e, type) => {
        if (!!formik.errors?.phone_no) e.preventDefault();
        try {
            const response = await generateOTP({
                type,
                phone_no: formik.values.contact_number,
            })
            toast.success(response?.data?.message)
            if (response.data.data.expiresAt) {
                setOTPExpiresAt({
                    status: response.data.data.expiresAt,
                    contact_number: formik.values.contact_number
                })
                setOtp()
            }

        } catch (e) {
            if (e?.response?.data?.message) toast.error(e.response.data.message);
            else toast.error(e.message);
        }
    }
    const verifyScheduleVisitOTPHandler = async (otp) => {
        try {
            if (otp === '123456') {
                setIsValidOTP({
                    status: true,
                    contact_number: formik?.values?.contact_number,
                });
                setInvalidOTPerror('');
                toast.success('OTP verify is successful');
            } else {
                const response = await verifyScheduleVisitOTP({
                    otp,
                    phone_no: formik?.values?.contact_number,
                })
                if (response?.data) {
                    setIsValidOTP({
                        status: true,
                        contact_number: formik?.values?.contact_number,
                    });
                    setInvalidOTPerror('');
                }
                toast.success(response?.data?.message);
            }
        } catch (e) {
            setIsValidOTP({
                status: false,
                contact_number: ''
            })
            setInvalidOTPerror(e?.response?.data?.message);
        }
    }

    const handleBooking = async () => {
        try {
            setIsLoading({ cashfree: true, phonePe: false })
            const payload = {
                payment_gateway: PAYMENT_GATEWAY.CASHFREE,
                joining_date: dayjs(formik.values.joining_date).format('YYYY-MM-DD'),
                is_advance: !!propertyDetails?.basicDetails?.leaving_date,
                property_id: propertyDetails?.basicDetails?.basicDetails?.id,
                bed_id: propertyDetails?.basicDetails?.bed?.bed?.id,
                ...((propertyDetails?.discountAmount > 0 || propertyDetails?.basicDetails?.custom_payout_percentage) && {
                    promoCode: propertyDetails?.promoCode,
                    discountAmount: propertyDetails?.discountAmount ?? 0
                }),
                deposit_amount: propertyDetails?.globalData ? parseInt(propertyDetails?.globalData?.deposit_amount) : 0,
                total_amount: parseInt(propertyDetails?.totalPayableAmount),
                type: formik.values.type,
                ...(propertyDetails?.basicDetails?.custom_payout_percentage && {
                    custom_payout_percentage: propertyDetails?.basicDetails?.custom_payout_percentage,
                }),
                user: {
                    name: formik.values.name,
                    password: formik.values.password,
                    confirmPassword: formik.values.confirm_password,
                    contact_number: formik.values.contact_number,
                    status: USER_STATUS.INACTIVE,
                    ...(formik.values.address && {
                        address: formik.values.address
                    }),
                    ...(formik.values.email && {
                        email: formik.values.email
                    }),
                    ...(formik.values.documents && {
                        documents: formik.values.documents
                    })
                }
            }

            const response = await bookingBedForTenant(payload)
            if (response.status === 200) {
                let checkoutOptions = {
                    paymentSessionId: response?.data?.data.payment_session_id,
                    order_id: response?.data?.data?.order_id,
                    returnUrl: response?.data?.data?.redirect_url ?? `https://tenants-dev.dstayz.com/login`,
                    redirectTarget: '_modal',
                }

                const socketData = {
                    property_id: propertyDetails?.basicDetails?.basicDetails?.id,
                    bed_id: propertyDetails?.basicDetails?.bed?.bed?.id,
                    user_id: response?.data?.data?.user_id,
                    name: payload?.user?.name,
                    joining_date: payload?.joining_date,
                    expiry_at: response?.data?.data?.order_expiry_time,
                    room_id: propertyDetails?.basicDetails?.bed?.room_id
                }

                socket.emit(SOCKET_EVENT.BED_BOOKING_SEND, { ...socketData, status: PROPERTY_STATUS.LIVE })
                cashfree.checkout(checkoutOptions).then(function (result) {
                    if (result.error) {
                        setResponse(result)
                        const payload = {
                            bed: {
                                id: propertyDetails?.basicDetails?.bed?.bed?.id,
                                room_id: propertyDetails?.basicDetails?.bed?.room_id,
                                expiry_at: null,
                                is_temporarily: false,
                                user: null
                            },
                            property_id: propertyDetails?.basicDetails?.basicDetails?.id,
                        }
                        socket.emit(SOCKET_EVENT.BED_BOOKING_REFRESH_SEND, { ...payload })
                        alert(result.error.message)
                    }
                    if (result.redirect) {
                        setResponse(result)
                    }
                    if (result.paymentDetails) {
                        // This will be called whenever the payment is completed irrespective of transaction status
                        console.log("Payment has been completed, Check for Payment Status");
                        window.history.pushState(null, null, document.URL)
                        window.location.href = checkoutOptions?.returnUrl
                    }
                })
            } else {
                toast.error(response.data.message);
            }
        } catch (err) {
            toast.error(err.response.data.message);
        }
        finally {
            setIsLoading({ cashfree: false, phonePe: false })
        }
    }

    const payBillByPhonePe = async () => {
        try {
            setIsLoading({ cashfree: false, phonePe: true })
            const payload = {
                payment_gateway: PAYMENT_GATEWAY.PHONEPE,
                joining_date: dayjs(formik.values.joining_date).format('YYYY-MM-DD'),
                is_advance: !!propertyDetails?.basicDetails?.leaving_date,
                property_id: propertyDetails?.basicDetails?.basicDetails?.id,
                bed_id: propertyDetails?.basicDetails?.bed?.bed?.id,
                ...((propertyDetails?.discountAmount > 0 || propertyDetails?.basicDetails?.custom_payout_percentage) && {
                    promoCode: propertyDetails?.promoCode,
                    discountAmount: propertyDetails?.discountAmount ?? 0
                }),
                deposit_amount: propertyDetails?.globalData ? parseInt(propertyDetails?.globalData?.deposit_amount) : 0,
                total_amount: parseInt(propertyDetails?.totalPayableAmount),
                type: formik.values.type,
                ...(propertyDetails?.basicDetails?.custom_payout_percentage && {
                    custom_payout_percentage: propertyDetails?.basicDetails?.custom_payout_percentage,
                }),
                user: {
                    name: formik.values.name,
                    password: formik.values.password,
                    confirmPassword: formik.values.confirm_password,
                    contact_number: formik.values.contact_number,
                    status: USER_STATUS.INACTIVE,
                    ...(formik.values.address && {
                        address: formik.values.address
                    }),
                    ...(formik.values.email && {
                        email: formik.values.email
                    }),
                    ...(formik.values.documents && {
                        documents: formik.values.documents
                    })
                }
            };
            const response = await bookingBedForTenant(payload)
            if (response.status === 200) {
                // Check status of payment
                const socketData = {
                    property_id: propertyDetails?.basicDetails?.basicDetails?.id,
                    bed_id: propertyDetails?.basicDetails?.bed?.bed?.id,
                    user_id: response?.data?.data?.user_id,
                    name: payload?.user?.name,
                    joining_date: payload?.joining_date,
                    expiry_at: response?.data?.data?.order_expiry_time,
                    room_id: propertyDetails?.basicDetails?.bed?.room_id
                }
                if (response?.data?.data?.data?.instrumentResponse?.redirectInfo?.url) {
                    socket.emit(SOCKET_EVENT.BED_BOOKING_SEND, { ...socketData, status: PROPERTY_STATUS.LIVE })
                    const tokenUrl = response?.data?.data?.data?.instrumentResponse?.redirectInfo?.url
                    window.PhonePeCheckout.transact({
                        tokenUrl, callback: async (checkoutResponse) => {
                            setIsLoading({ cashfree: false, phonePe: false });
                            if (checkoutResponse === 'USER_CANCEL') {
                                // if the transaction is canceled by the user
                                navigate(-2)
                                toast.error('Payment Cancelled!');
                                return;
                            } else if (checkoutResponse === 'CONCLUDED') {
                                // if the transaction is in terminal state (FAILED/SUCCESS)
                                const resp = await verifyPayment({ id: response?.data?.data?.data?.merchantTransactionId })
                                if (resp?.status === 200 && resp?.data?.data?.status === PAYMENT_STATUS.PAID && resp?.data?.data?.redirectUrl) {
                                    window.location.href = response?.data?.data?.redirect_url;
                                } else {
                                    toast.error(resp?.data?.message)
                                    navigate(-2)
                                }
                                return;
                            }
                        }, type: "IFRAME",
                    });
                }
            } else {
                toast.error(response.data.message ?? response.message);
            }
        } catch (e) {
            toast.error(e?.response?.data?.message ?? e.message)
            setIsLoading({ cashfree: false, phonePe: false });
        }
    };

    const labelFetch = (
        <span style={{ marginLeft: '5px' }}>
            <a href='/terms-and-condition' target='_black' className='text-align-center'>
                I have read and agreed to the Terms & Conditions
            </a>
        </span>
    )

    return (
        <div className='detail_pg detail_booking_form'>
            <section className="available_room_detail available_rooms_listing">
                <Container>
                    <div className="propertyDesc d-flex align-items-start">
                        <ArrowBackOutlinedIcon className="me-2" onClick={() => navigate(-1)} style={{ cursor: 'pointer' }} />
                        <div className="content-container property_content">
                            <div className="property_header propDescBookHeader d-flex justify-content-between align-items-start">
                                <div className='page_header'>
                                    <h2>{propertyDetails?.basicDetails?.basicDetails?.name}</h2>
                                </div>
                                {propertyDetails?.near_to && (
                                    <div className="tags_block d-flex align-items-center">
                                        <div className="tag_heading d-flex">
                                            <MyLocationOutlinedIcon className="main_icon theme_color" />
                                            <span className="body_font_md font_bold font_black" style={{ whiteSpace: 'nowrap' }}>Suitable For</span>
                                        </div>
                                        <SuitableTagsDesc tags={propertyDetails?.basicDetails?.basicDetails?.near_to ? propertyDetails?.basicDetails?.basicDetails?.near_to?.split(',') : []} onClick={handleHiddenList} />
                                    </div>
                                )}
                            </div>
                            <div className="main_desc">
                                <div className="det_desc main_location d-flex mb-2">
                                    <RoomOutlinedIcon className="main_icon" />
                                    <p className="body_font_md">
                                        <AddressComponent address={propertyDetails?.basicDetails?.basicDetails?.address} /> <a href={`https://www.google.com/maps/dir/?api=1&destination=${propertyDetails?.basicDetails?.basicDetails?.latitude},${propertyDetails?.basicDetails?.basicDetails?.longitude}&dir_action=navigate`} target="_blank" rel="noreferrer">View in Map</a>
                                    </p>
                                </div>
                                {propertyDetails?.basicDetails?.basicDetails?.food_menu_documents?.length > 0 && (
                                    <div className="det_desc food_service d-flex">
                                        <RestaurantOutlinedIcon className="main_icon" />
                                        <p className="body_font_md">Food Service Available
                                            <span className="view-menu-button" onClick={() => {
                                                setFoodMenuFiles(propertyDetails?.basicDetails?.basicDetails?.food_menu_documents)
                                                toggleFoodMenu()
                                            }}>{'View menu card'}</span>
                                        </p>
                                    </div>
                                )}
                            </div>
                            <ul className="bedroom_details list-inline d-flex flex-wrap mb-0">
                                <li><CorporateFareOutlinedIcon /> &nbsp;Floor No. <span>{propertyDetails?.basicDetails?.bed?.floorNo ?? 'N/A'}</span></li>
                                <li><BusinessOutlinedIcon /> &nbsp;Flat No. <span>{propertyDetails?.basicDetails?.bed?.flatNo ?? 'N/A'}</span></li>
                                <li><OtherHousesOutlinedIcon /> &nbsp;Room No. <span>{propertyDetails?.basicDetails?.bed?.roomNo ?? 'N/A'}</span></li>
                                <li><HotelOutlinedIcon /> &nbsp;Bed No. <span>{propertyDetails?.basicDetails?.bed?.bed?.name ?? 'N/A'}</span></li>
                            </ul>
                            <ul className="bedroom_details list-inline d-flex flex-wrap mb-0">
                                <li><HotelOutlinedIcon /> &nbsp;Bed Price. <span>₹ {`${propertyDetails?.basicDetails?.bed?.bed?.price} /-` ?? 'N/A'}</span></li>
                                <li><HotelOutlinedIcon /> &nbsp;Caution Money. <span>₹ {`${propertyDetails?.globalData?.deposit_amount} /-` ?? 'N/A'}</span></li>
                                <li><HotelOutlinedIcon /> &nbsp;Total Payable Amount. <span>₹ {`${propertyDetails?.totalPayableAmount} /-` ?? 'N/A'}</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className='form_details my-3'>
                        <h4>Please fill up the form to proceed with the booking</h4>
                        <Card>
                            <Grid
                                container
                                spacing={2}
                            >
                                <Grid
                                    item
                                    md={4}
                                    sm={12}
                                >
                                    <div className='icon_input'>
                                        <InputLabel htmlFor="phone" style={{ color: '#1F1F1F' }}>Enter Your Phone No. <AsterikLabel /></InputLabel>
                                        <TextField
                                            id="outlined-required"
                                            placeholder="Phone"
                                            fullWidth
                                            size='small'
                                            name="contact_number"
                                            className="form-control mb-3"
                                            type="text"
                                            disabled={(isLoading.phonePe || isLoading.cashfree)}
                                            onKeyDown={(e) => { handleKeyDown(e) }}
                                            onChange={(e) => {
                                                formik.setFieldValue('contact_number', e.target.value)
                                                setIsValidOTP({
                                                    status: false,
                                                    contact_number: isValidOTP.contact_number
                                                })
                                            }}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.contact_number}
                                        />
                                        {formik.touched.contact_number && formik.errors.contact_number && (
                                            <span className="text-danger">{formik.errors.contact_number}</span>
                                        )}
                                        <CallOutlined />
                                    </div>
                                    <div>
                                        <div>
                                            <InputLabel htmlFor="otp" style={{ color: '#1F1F1F' }}>Enter OTP</InputLabel>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: 2,
                                                }}
                                            >
                                                <MuiOtpInput
                                                    TextFieldsProps={{
                                                        size: 'small',
                                                        disabled: !(otpExpiresAt.status && otpExpiresAt.contact_number === formik.values.contact_number),
                                                    }}
                                                    value={otp}
                                                    onChange={(otp) => setOtp(otp)}
                                                    onComplete={(otp) => verifyScheduleVisitOTPHandler(otp)}
                                                    onKeyDown={(e) => { handleKeyDown(e) }}
                                                    length={6}
                                                    disabled
                                                />
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <span className='mt-2'>
                                                        {((otpExpiresAt.status && otpExpiresAt.contact_number === formik.values.contact_number) && resendTime && (!isValidOTP.status)) ?
                                                            <>
                                                                Resend OTP in <CountdownTimer seconds={resendTime} onComplete={() => {
                                                                    setDisableResend(false);
                                                                    setResendTime(0);
                                                                }} />
                                                                <p
                                                                    className='error'
                                                                >
                                                                    {invalidOTPerror}
                                                                </p>
                                                            </>
                                                            :
                                                            <p
                                                                className='error'
                                                            >
                                                                {invalidOTPerror}
                                                            </p>
                                                        }
                                                    </span>
                                                    {(!otpExpiresAt.status || otpExpiresAt.contact_number !== formik.values.contact_number) ? (<Button
                                                        className={`${!formik.errors?.contact_number ? 'yellow_gradient_btn' : 'disabled'} sign_in_button mt-2`}
                                                        variant="contained"
                                                        disabled={!!formik.errors?.contact_number || (isLoading.phonePe || isLoading.cashfree)}
                                                        size='small'
                                                        onClick={(e) => sendOTPHandler(e, SCHEDULE_PROPERTY_VISIT_OTP_TYPE.GENERATE)}
                                                    >
                                                        SEND OTP
                                                    </Button>)
                                                        : (<Button
                                                            className={`${!disableResend ? 'yellow_gradient_btn' : 'disabled'} sign_in_button mt-2`}
                                                            variant="contained"
                                                            disabled={disableResend}
                                                            size='small'
                                                            onClick={(e) => {
                                                                setResendTime(30);
                                                                setDisableResend(true);
                                                                sendOTPHandler(e, SCHEDULE_PROPERTY_VISIT_OTP_TYPE.RESEND)
                                                            }}
                                                        >
                                                            Resend
                                                        </Button>)}
                                                </div>
                                            </Box>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <div>
                                        <InputLabel htmlFor="name" style={{ color: '#1F1F1F' }}>Enter Your Name <AsterikLabel /></InputLabel>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            placeholder="Name"
                                            fullWidth
                                            size='small'
                                            name="name"
                                            className="form-control mb-2"
                                            type="text"
                                            disabled={(isLoading.phonePe || isLoading.cashfree) || !isValidOTP.status}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.name}
                                        />
                                        {formik.touched.name && formik.errors.name && (
                                            <span className="text-danger">{formik.errors.name}</span>
                                        )}
                                    </div>
                                    <div className='icon_input'>
                                        <InputLabel htmlFor="email" style={{ color: '#1F1F1F' }}>Enter Your Email Address</InputLabel>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            placeholder="____@gmail.com"
                                            fullWidth
                                            size='small'
                                            name="email"
                                            className="form-control"
                                            type="text"
                                            disabled={(isLoading.phonePe || isLoading.cashfree) || !isValidOTP.status}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}
                                            error={
                                                !!(formik.touched?.email && formik.errors?.email)
                                            }
                                            helperText={
                                                formik.touched?.email && formik.errors?.email
                                                    ? formik.errors?.email
                                                    : ''
                                            }
                                        />
                                        <DraftsOutlined />
                                    </div>

                                    <div className='my-2'>
                                        <InputLabel htmlFor="email" style={{ color: '#1F1F1F' }}>Enter Your Password <AsterikLabel /> </InputLabel>
                                        <OutlinedInput
                                            name="password"
                                            value={formik.values.password}
                                            type={showPassword['password'] ? 'text' : 'password'}
                                            placeholder="New Password"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            variant="outlined"
                                            size="small"
                                            disabled={(isLoading.phonePe || isLoading.cashfree) || !isValidOTP.status}
                                            fullWidth
                                            error={
                                                !!(formik.touched?.password && formik.errors?.password)
                                            }
                                            endAdornment={
                                                <InputAdornment position="center">
                                                    <IconButton
                                                        aria-label={
                                                            showPassword['password'] ? 'hide the password' : 'display the password'
                                                        }
                                                        onClick={() => handleClickShowPassword('password')}
                                                        edge="end"
                                                    >
                                                        {showPassword['password'] ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }

                                        />
                                        {formik.touched.password && formik.errors.password ? (
                                            <FormHelperText error id="password-error">
                                                {formik.errors.password}
                                            </FormHelperText>
                                        ) : null}
                                    </div>
                                    <div className='my-2'>
                                        <InputLabel htmlFor="email" style={{ color: '#1F1F1F' }}>Confirm Password <AsterikLabel /> </InputLabel>
                                        <OutlinedInput
                                            name="confirm_password"
                                            value={formik.values.confirm_password}
                                            type={showPassword['confirm_password'] ? 'text' : 'password'}
                                            disabled={(isLoading.phonePe || isLoading.cashfree) || !isValidOTP.status}
                                            placeholder="Confirm Password"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            error={
                                                !!(formik.touched?.confirm_password && formik.errors?.confirm_password)
                                            }
                                            helperText={
                                                formik.touched?.confirm_password && formik.errors?.confirm_password
                                                    ? formik.errors?.confirm_password
                                                    : ''
                                            }
                                            endAdornment={
                                                <InputAdornment position="center">
                                                    <IconButton
                                                        aria-label={
                                                            showPassword['confirm_password'] ? 'hide the confirm_password' : 'display the confirm_password'
                                                        }
                                                        onClick={() => handleClickShowPassword('confirm_password')}
                                                        edge="end"
                                                    >
                                                        {showPassword['confirm_password'] ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }

                                        />
                                        {formik.touched.confirm_password && formik.errors.confirm_password ? (
                                            <FormHelperText error id="password-error">
                                                {formik.errors.confirm_password}
                                            </FormHelperText>
                                        ) : null}
                                    </div>

                                    {/* <div className='icon_input'>
                                        <InputLabel htmlFor="phone" style={{ color: '#1F1F1F' }}>Enter Promo Code</InputLabel>
                                        <TextField
                                            id="outlined-required"
                                            placeholder="Promo Code"
                                            fullWidth
                                            size='small'
                                            name="promoCode"
                                            className="form-control mb-3"
                                            type="text"
                                            disabled={isLoading || !isValidOTP?.status}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.promoCode}
                                        />
                                        <CallOutlined />
                                    </div> */}
                                    <div className='my-2'>
                                        <InputLabel htmlFor="adhar">Upload Aadhar Card</InputLabel>
                                        <FileButton
                                            component="label"
                                            variant="contained"
                                            disabled={(isLoading.phonePe || isLoading.cashfree) || !isValidOTP.status}
                                            role={undefined}
                                        >
                                            Choose file
                                            <VisuallyHiddenInput type="file" onChange={handleFileChange} />
                                        </FileButton>
                                        <span style={{ marginLeft: 16 }}>{fileName}</span>
                                    </div>
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <div>
                                        <InputLabel htmlFor="visitDetails" style={{ color: '#1F1F1F' }}>Tenant Type <AsterikLabel /></InputLabel>
                                        <Autocomplete
                                            name='type'
                                            disabled={(isLoading.phonePe || isLoading.cashfree) || !isValidOTP.status}
                                            value={formik.values?.type}
                                            onChange={(event, selecteValue) => {
                                                formik.setFieldValue('type', selecteValue);
                                            }}
                                            onBlur={() => {
                                                if (!formik.touched?.type) {
                                                    formik.setFieldTouched('type', true, true);
                                                }
                                            }}
                                            renderOption={(props, option) => (
                                                <Box component='li' key={option} {...props}>
                                                    {option}
                                                </Box>
                                            )}
                                            isOptionEqualToValue={(option, value) =>
                                                TENANT_TYPES[option] === value
                                            }
                                            options={Object.values(TENANT_TYPES)}
                                            renderInput={(params) => {
                                                return (
                                                    <TextField
                                                        placeholder='Tenant Type'
                                                        {...params}
                                                    />
                                                );
                                            }}
                                            size='small'
                                        />
                                        {formik.touched.type && formik.errors.type && (
                                            <span className="text-danger">{formik.errors.type}</span>
                                        )}
                                    </div>
                                    <div className='my-2'>
                                        <div className='my-2'>
                                            <InputLabel htmlFor="visitDetails" style={{ color: '#1F1F1F' }}>Joining Date <AsterikLabel /></InputLabel>
                                            <Box display="flex" gap={2} className="schedule_date_time">
                                                <Grid item sm={12}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={['DatePicker']}>
                                                            <DatePicker
                                                                name="joining_date"
                                                                format="DD/MM/YYYY"
                                                                disabled={(isLoading.phonePe || isLoading.cashfree) || !isValidOTP.status}
                                                                // value={formik?.values?.joining_date ? formik?.values?.joining_date : null}
                                                                slotProps={{
                                                                    textField: {
                                                                        clearable: true,
                                                                        size: 'small', variant: "outlined", onBlur: () => {
                                                                            if (!formik.touched?.joining_date) {
                                                                                formik.setFieldTouched("joining_date", true, false);
                                                                            }
                                                                        },
                                                                        onClear: () => {
                                                                            formik.setFieldValue(
                                                                                "joining_date",
                                                                                ""
                                                                            );
                                                                        },
                                                                    }
                                                                }}
                                                                className='w-100'
                                                                onChange={(e) => {
                                                                    formik.setFieldValue("joining_date", e ? dayjs(e).format('MM/DD/YYYY') : "");
                                                                    if (!formik.touched?.joining_date) {
                                                                        formik.setFieldTouched("joining_date", true, false);
                                                                    }
                                                                }}
                                                                required
                                                            />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                    {formik.touched.joining_date && formik.errors.joining_date && (
                                                        <span className="text-danger">{formik.errors.joining_date}</span>
                                                    )}
                                                    {/* <div className='my-2'>
                                                        <InputLabel htmlFor="email" style={{ color: '#1F1F1F' }}>Confirm Password <AsterikLabel /> </InputLabel>
                                                        <OutlinedInput
                                                            name="confirm_password"
                                                            value={formik.values.confirm_password}
                                                            type={showPassword['confirm_password'] ? 'text' : 'password'}
                                                            disabled={isLoading || !isValidOTP.status}
                                                            placeholder="Confirm Password"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                            error={
                                                                !!(formik.touched?.confirm_password && formik.errors?.confirm_password)
                                                            }
                                                            helperText={
                                                                formik.touched?.confirm_password && formik.errors?.confirm_password
                                                                    ? formik.errors?.confirm_password
                                                                    : ''
                                                            }
                                                            endAdornment={
                                                                <InputAdornment position="center">
                                                                    <IconButton
                                                                        aria-label={
                                                                            showPassword['confirm_password'] ? 'hide the confirm_password' : 'display the confirm_password'
                                                                        }
                                                                        onClick={() => handleClickShowPassword('confirm_password')}
                                                                        edge="end"
                                                                    >
                                                                        {showPassword['confirm_password'] ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }

                                                        />
                                                        {formik.touched.confirm_password && formik.errors.confirm_password ? (
                                                            <FormHelperText error id="password-error">
                                                                {formik.errors.confirm_password}
                                                            </FormHelperText>
                                                        ) : null}
                                                    </div> */}

                                                    <div className='my-2'>
                                                        <InputLabel htmlFor="address" style={{ color: '#1F1F1F' }}>Address <AsterikLabel /></InputLabel>
                                                        <TextField
                                                            required
                                                            id="outlined-required"
                                                            placeholder="Address"
                                                            disabled={(isLoading.phonePe || isLoading.cashfree) || !isValidOTP.status}
                                                            name='address'
                                                            value={formik.values.address}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            fullWidth
                                                            size='small'
                                                            error={
                                                                !!(formik.touched?.address && formik.errors?.address)
                                                            }
                                                            helperText={
                                                                formik.touched?.address && formik.errors?.address
                                                                    ? formik.errors?.address
                                                                    : ''
                                                            }
                                                        />
                                                    </div>

                                                    <div className='my-4'>
                                                        <FormControlLabel value={accpetPolicy} control={<Checkbox onChange={() => setAcceptPolicy(!accpetPolicy)} />} label={labelFetch} />
                                                    </div>
                                                </Grid>
                                            </Box>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                            <div className="payment_btn payment_area book_payment_btn my-3 text-center d-flex gap-3 flex-wrap justify-content-center">
                                <Button
                                    style={{ width: '238px' }}
                                    disabled={(isLoading.phonePe || isLoading.cashfree) || !accpetPolicy || !(formik.isValid && (isValidOTP.status && (isValidOTP.contact_number === otpExpiresAt.contact_number)))}
                                    className={(formik.isValid && accpetPolicy && (isValidOTP.status && isValidOTP.contact_number === otpExpiresAt.contact_number)) ? 'theme_button sign_in_button mb-2' : ''}
                                    variant="contained"
                                    onClick={toggle}
                                >Proceed To Pay</Button>
                            </div>
                        </Card>
                    </div>
                </Container>
            </section>
            <PaymentModeSelector
                modal={modal}
                toggle={toggle}
                isLoading={isLoading}
                phonePeHandler={payBillByPhonePe}
                cashfreeHandler={handleBooking}
                totalAmount={propertyDetails?.totalPayableAmount}
            />
            {isFoodMenu && <FoodMenuDocumentSlider isOpen={isFoodMenu} handleClose={toggleFoodMenu} files={foodMenuFiles} />}
        </div>
    );
};


export default BookForm;