import React from 'react';
import { Container, Grid, Link, Box } from '@mui/material';
import Logo from "../../assets/images/logo.png";
import { CMS_PAGES } from '../../constants/variables';
import SocialMedia from './SocialMedia';

const Footer = () => {
    return(
        <div className='main_footer'>
            <Container>
                <Grid container spacing={4} className='footer_upper_content align-items-center'>
                    <Grid item xs={12} sm={6} md={3}>
                        <div className='main_logo'>
                            <img src={Logo} alt="logo" />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} 
                    style={{ paddingLeft: '65px'}}
                    >
                        <div className='footer_contact_links'>
                            <nav>
                                <Link href={`/${CMS_PAGES.ABOUT_US.split('_').join('-')}`}>
                                    About Us
                                </Link>
                                <Link href={`/${CMS_PAGES.CONTACT_US.split('_').join('-')}`}>
                                    Contact Us
                                </Link>
                                {/* <Link href="/">
                                    Join Us
                                </Link> */}

                                {/* Adding policy pages */}
                                {[CMS_PAGES.CANCEL_POLICY, 
                                CMS_PAGES.PRIVACY_POLICIES, 
                                CMS_PAGES.GUESTS_POLICY, 
                                CMS_PAGES.REFUND_POLICY, 
                                CMS_PAGES.TERMS_AND_CONDITION].map((key) => (
                                <Link href={`/${key.split('_').join('-')}`}>
                                    {`${key.split('_').map((word)=>(word.charAt(0).toUpperCase() + word.slice(1))).join(' ')}`}
                                </Link>
                                ))}
                            </nav>  
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <div className='footer_contact'>
                        <div className='contact_item'>Phone / Whatsapp : <span> 9050008000</span></div>
                        <div className='contact_item'>Email : <span> dstayz@gmail.com</span></div>
                        <SocialMedia/>
                    </div>
                    </Grid>
                </Grid>
                {/* <Box mt={3} className='footer_below_content text-center'>
                    <nav>
                        <Link href="/">
                            Privacy Policies
                        </Link>
                        <Link href="/">
                            Terms & Conditions
                        </Link>
                    </nav>  
                </Box> */}
            </Container>
        </div>
    )
}

export default Footer;



// const Footer = () => {
//   return (
    
//   );
// };

// export default Footer;
